import { useStaticQuery, graphql } from 'gatsby'

export const AllMapDataEn = () => {
  const { allContentfulElementLink } = useStaticQuery(
    graphql`
      query AllMapDataEnQuery {
        allContentfulElementLink(
          filter: { node_locale: { eq: "en" }, icon: { eq: "google" } }
        ) {
          edges {
            node {
              slug
              label
              node_locale
              location {
                lat
                lon
              }
              content___post {
                slug
                title
                date(formatString: "YYYY-MM-DD")
                mapInfo {
                  field {
                    raw
                  }
                }
                thumbnail {
                  gatsbyImageData(width: 600, quality: 70)
                }
                category {
                  name
                  slug
                }
                tags {
                  slug
                  group
                  name
                }
                links {
                  icon
                  url
                  slug
                }
              }
            }
          }
        }
      }
    `
  )
  return allContentfulElementLink
}
