import React, { Component } from 'react'
import { css } from '@emotion/react'
import * as Style from '../../libs/styles'
class Logo extends Component {
  render () {
    return (
      <svg
        css={style}
        viewBox='0 0 936 115.2'
        preserveAspectRatio='xMinYMin meet'
      >
        <use xlinkHref='#logo' x='0' y='0' />
      </svg>
    )
  }
}
const style = () => css`
  fill: #000;
  ${Style.Mq.morePc} {
    height: 53px;
  }
  ${Style.Mq.tb} {
    height: 40px;
  }
  ${Style.Mq.lessPab} {
    height: 25px;
  }
`
export default Logo
