import React from 'react'

import { css } from '@emotion/react'

const CheckBox = props => {
  const checkboxCss = () => css`
    cursor: pointer;
    position: relative;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    > label {
      display: flex;
      align-items: center;
      column-gap: 12px;
    }
    span {
      position: relative;
    }
    span.label {
      margin-left: 25px;
    }
    .checkmark {
      height: 20px;
      width: 20px;
      border: 1px solid #000;
      background-color: #fff;
      position: absolute;
      left: 0px;
    }

    &:hover input ~ .checkmark {
      background-color: #fff;
    }

    & input:checked ~ .checkmark {
      background-color: #fff;
    }

    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    & input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    & .checkmark:after {
      left: 6px;
      top: 4px;
      width: 5px;
      height: 10px;
      border: solid #000;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  `
  const { children, value, id, name = 'cb', checked, ...rest } = props

  return (
    <>
      <div css={checkboxCss} {...rest}>
        <label>
          <span className='label'>{children}</span>
          <input
            id={id}
            name={name}
            value={value}
            type='checkbox'
            defaultChecked={checked}
          />
          <span class='checkmark'></span>
        </label>
      </div>
    </>
  )
}

export default CheckBox
