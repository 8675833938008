import React, { useContext } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";

import { useDispatch } from "react-redux";
import StoreContext from "libs/context/StoreContext";
import * as Action from "libs/redux/action";
import * as Libs from "libs";
const SelectLanguage = (props) => {
	const dispatch = useDispatch();
	const { restoreCheckout } = useContext(StoreContext);
	const clickHandler = (event, id) => {
		const key = event.currentTarget.dataset.key;

		Libs.Common.Config.setCurrentLang(key);
		restoreCheckout();
		dispatch(Action.LangChanged(key));
	};

	let langs = Libs.Common.Func.getLangInfo();

	const iconCss = (props) => css`
		width: 20px;
		height: 20px;
		display: inline-block;
		vertical-align: middle;
		margin: 0 10px;
	`;
	if (langs && langs.length === 1) {
		const langKey = Libs.Common.Func.getLangKey();
		langs = [
			{
				langKey: "ja",
				selected: langKey === "ja" ? true : false,
				link: "/ja",
			},
			{
				langKey: "en",
				selected: langKey === "en" ? true : false,
				link: "/en",
			},
		];
	}
	const links = langs
		? langs.map((lang) => (
				<li data-selected={lang.selected}>
					<Libs.Atoms.TriggerLink
						to={lang.link}
						key={lang.langKey}
						data-key={lang.langKey}
						onClick={(event) => clickHandler(event)}
						style={{
							color: "white",
						}}
					>
						<div css={iconCss}>
							<Libs.Atoms.Image
								alt={lang.langKey}
								src={`icon-${lang.langKey}.png`}
							/>
						</div>
						<Libs.Atoms.Span>{lang.langKey}</Libs.Atoms.Span>
					</Libs.Atoms.TriggerLink>
				</li>
		  ))
		: null;
	return (
		<ul css={Libs.Styles.Flex.flexList} {...props}>
			{links}
		</ul>
	);
};

SelectLanguage.propTypes = {
	langs: PropTypes.array,
};

export default SelectLanguage;
