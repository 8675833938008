import React from 'react'
import { css } from '@emotion/react'
import * as Common from '../../common'

import * as Libs from 'libs'

export default props => {
  const { params } = props
  const content = params.content
  const lang = params.lang
  const isArticle = false

  const headerContCss = () => css`
    .category-name {
      text-transform: uppercase;
    }
    display: grid;
    row-gap: 1rem;
    max-width: 640px;
    grid-auto-rows: min-content;
  `
  const buttonCss = () => css`
    display: inline-block;
    background-color: #000;
    cursor: pointer;
    border: none;
    outline: none;
    color: #fff !important;
    border-radius: 30px;
    padding: 1.0605rem 2rem;
    position: relative;
    z-index: 0;
    white-space: nowrap;
    pointer-events: auto;
    margin-right: 1rem;
  `
  const linkContCss = () => css`
    display: inline-block;
    > * {
      margin-left: 0.5rem;
      svg {
        vertical-align: middle;
      }
      :first-child {
        margin-left: 0px;
      }
    }
    ${Libs.Styles.Mq.lessPab} {
      display: block;
      margin-top: 1.25rem;
    }
  `

  const linkListCss = () => css`
    ${Libs.Styles.Mq.lessPab} {
      display: block;
    }
  `
  const h4Css = () => css`
    ${Libs.Common.Func.getPcSpVwValue('margin-top', 32, 14, true)};
    ${Libs.Common.Func.getPcSpVwValue('margin-bottom', 5, 4, true)};
  `
  const tagContCss = () => css`
    a {
      margin-right: 0.5rem;
      &:last-child {
        margin-right: 0rem;
      }
    }
  `

  const getCategoryName = content => {
    return content.tags
      ? content.tags.map(props => {
          if (props.group === 'Category') {
            return '> ' + props.name
          }
        })
      : ''
  }
  const getTagsElement = content => {
    return content.tags ? (
      <div css={tagContCss}>
        {content.tags.map(props => {
          if (props.group === 'Area' || props.group === 'Team') {
            return (
              <Libs.Atoms.TagButton
                to={`/${lang}/${props.group.toLowerCase()}/${props.slug}/`}
              >
                {props.name}
              </Libs.Atoms.TagButton>
            )
          }
        })}
      </div>
    ) : (
      ''
    )
  }
  const getTitleElement = content => {
    return <Libs.Atoms.H1>{content.title}</Libs.Atoms.H1>
  }
  let location
  if (content.links) {
    content.links.map((link, i) => {
      if (!location && link.location) {
        location = link.location
      }
    })
  }

  return (
    <div css={headerContCss}>
      <Libs.Atoms.P
        className={'category-name'}
        styles={{
          fontPc: {
            fontSize: 12,
            lineHeight: 12,
            letterSpacing: 1,
            fontFace: 'sansserif',
            bold: true,
            color: Common.Func.getColor(content.category.slug)
          },
          fontSp: {
            fontSize: 12,
            lineHeight: 12,
            letterSpacing: 1,
            fontFace: 'sansserif',
            bold: true,
            color: Common.Func.getColor(content.category.slug)
          }
        }}
      >
        {content.category.name} {getCategoryName(content)}
      </Libs.Atoms.P>
      {getTitleElement(content)}
      <Libs.Atoms.H4 css={h4Css}>{content.name}</Libs.Atoms.H4>

      {content.links ? (
        <div css={linkListCss}>
          {location ? (
            <Libs.Atoms.TriggerLink
              css={buttonCss}
              to={`/${content.node_locale}/map/`}
              onClick={() => {
                Common.Config.location = location
              }}
            >
              {content.node_locale === 'en' ? 'View on Map' : 'Mapで見る'}
            </Libs.Atoms.TriggerLink>
          ) : (
            ''
          )}
          <div css={linkContCss}>
            {content.links.map((link, i) => {
              return (
                <a className={'link-item'} href={link.url} target='_new'>
                  <Libs.Atoms.Icon type={link.icon} />
                </a>
              )
            })}
          </div>
        </div>
      ) : (
        ''
      )}
      {getTagsElement(content)}
    </div>
  )
}
