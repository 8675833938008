import * as Transition from '../components/Transition'
import * as Modules from '../components/modules'
import React from 'react'
import * as Styles from '../libs/styles'
import * as Hooks from '../hooks'
let postData = null
export const categoryMap = new Map()
export const tagsMap = new Map()

export const getLoadTypeFunction = loadTypeString => {
  if (loadTypeString) {
    const tmpTyp = loadTypeString
    if (tmpTyp === 'InitIndexTransition') {
      return Transition.InitTransition.InitIndexTransition
    } else {
      return ''
    }
  }
}
export const sortByOrder = (targetArray, orders) => {
  var newArray = []
  for (var i = 0, len = targetArray.length; i < len; ++i) {
    newArray.push(targetArray[orders[i]])
  }
  return newArray
}

export const setMapData = (lang, data) => {
  data.edges.map((item, i) => {
    const contentPost = item.node.content___post

    if (contentPost) {
      const len = contentPost.length
      let post
      if (len > 1) {
        post = contentPost.filter(post => {
          const posts = post.tags.filter(tag => {
            // オブジェクトを配列に変換
            if (tag.group === 'Category') return true
          })

          return posts.length >= 1 ? true : false
        })
        post = post[0]
      } else {
        post = contentPost[0]
      }
      if (!post) return false
      if (!categoryMap.has(post.category.slug)) {
        const newMap = new Map()
        categoryMap.set(post.category.slug, newMap)
        tagsMap.set(post.category.slug, post.category)
      }
      if (post.tags) {
        if (post.tags.find(element => element.group === null)) {
          const catMap = categoryMap.get(post.category.slug)
          const slug = `${post.category.slug}-nocategory`
          if (!catMap.has(slug)) {
            const newMap = new Map()
            catMap.set(slug, newMap)
          }

          /*post.tags = [
            {
              slug: slug,
              name: 'No Category',
              group: 'Category'
            }
          ]

          const tagCat = catMap.get(slug)
          tagCat.set(post.slug, post)*/
        } else {
          post.tags.map(tag => {
            const group = tag.group
            if (group === 'Category') {
              const catMap = categoryMap.get(post.category.slug)
              if (!catMap.has(tag.slug)) {
                const newMap = new Map()
                catMap.set(tag.slug, newMap)
                tagsMap.set(tag.slug, tag)
              }
              const tagCat = catMap.get(tag.slug)
              tagCat.set(post.slug, post)
            }
          })
        }
      } else {
        const catMap = categoryMap.get(post.category.slug)
        const slug = `${post.category.slug}-nocategory`
        post.tags = [
          {
            slug: slug,
            name: 'No Category',
            group: 'Category'
          }
        ]

        if (!catMap.has(slug)) {
          const newMap = new Map()
          catMap.set(slug, newMap)
        }
        const tagCat = catMap.get(slug)
        tagCat.set(post.slug, post)
      }
    }
  })
}
export const restoreData = props => {
  postData = null
}

export const getData = langkey => {
  //if (postData) return postData
  let PostEdges =
    langkey == 'ja'
      ? Hooks.usePostListData.useJaPostData()
      : Hooks.usePostListDataEn.useEnPostData()

  let edges = PostEdges
  edges = edges.map(({ node }) => {
    return node
  })
  postData = edges
  return postData
}
export const getColor = props => {
  if (props === 'food-and-drink') {
    return '#da8a31'
  } else if (props === 'art-culture') {
    return '#58b3c8'
  } else if (props === 'fashion') {
    return '#d74f7a'
  } else if (props === 'living') {
    return '#5a8aed'
  } else if (props === 'kids') {
    return '#5f6ab9'
  }
}
export const getFeatureLayoutCard = props => {
  if (props) {
    return props.map((card, i) => {
      const cardType = {
        layout: getCardType(card.layoutRef)
      }
      card.onLoadType = 'InitIndexTransition'
      return (
        <cardType.layout
          className={card.onLoadType ? 'init-animat-cont' : ''}
          info={card}
        />
      )
    })
  } else {
    return ''
  }
}
export const getBodyLayoutCard = props => {
  if (props) {
    return props.map((card, i) => {
      const cardType = {
        layout: getCardType(card.layoutRef)
      }
      return (
        <section css={Styles.Mixin.containerCss}>
          <cardType.layout info={card} />
        </section>
      )
    })
  } else {
    return ''
  }
}
export const getCardType = cardTypeString => {
  if (cardTypeString === 'layout-card01') {
    return Modules.LayoutCard01
  } else if (cardTypeString === 'layout-card02') {
    return Modules.LayoutCard02
  } else if (cardTypeString === 'layout-card03') {
    return Modules.LayoutCard03
  } else if (cardTypeString === 'layout-card04') {
    return Modules.LayoutCard04
  } else if (cardTypeString === 'layout-card05') {
    return Modules.LayoutCard05
  } else if (cardTypeString === 'layout-card06') {
    return Modules.LayoutCard06
  } else if (cardTypeString === 'layout-spread-card01') {
    return Modules.LayoutSpreadCard01
  } else {
    return Modules.LayoutCard02
  }
}
export const getElementType = key => {
  let element = ''
  if (key === 'Title-Header') {
    element = Modules.ContentTitleHeader
  } else if (key === 'Name-Header') {
    element = Modules.ContentTitleHeader
  } else if (key === 'SnsList') {
    element = Modules.SnsList
  } else {
    return false
  }
  return {
    element: element
  }
}
export const setType = element => {
  const node = element ? element : document.querySelector('.project-container')
  const dataset = node.dataset
  const categoryClass = dataset.categoryslug
    ? 'category-' + dataset.categoryslug
    : 'category-index'
  const cont = document.querySelector('html')
  cont.classList.remove(
    'type-category',
    'type-page',
    'type-post',
    'category-works',
    'category-shop',
    'category-index',
    'category-journal',
    'category-about',
    'category-contact',
    'category-howltcoffee'
  )
  cont.classList.add(`type-${dataset.type}`, `${categoryClass}`)
  // const section = node.querySelectorAll("section:first-child")
  return
}
