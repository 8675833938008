import { useStaticQuery, graphql } from "gatsby";

export const AllTagData = () => {
	const { allContentfulCommonTag } = useStaticQuery(
		graphql`
			query AllTagDataQuery {
				allContentfulCommonTag {
					edges {
						node {
							name
							slug
							group
						}
					}
				}
			}
		`
	);
	return allContentfulCommonTag;
};
