import React, { useEffect, useContext, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import * as Action from 'libs/redux/action'
import * as Common from '../../common'
import { gsap, Power3 } from 'gsap'
import * as Libs from 'libs'
let currentMenuState = false

export default props => {
  let mount = false

  const dispatch = useDispatch()

  const langKey = Libs.Common.Func.getLangKey()
  const [lang, setLang] = useState(langKey)

  const current = null
  let isMobile = false
  if (Libs.Common.Func.getDeviceSize() === 'lessPab') {
    isMobile = true
  }

  let menuState = useSelector(state => {
    return state.ClickReducer.onMenu
  })
  const tl = gsap.timeline()

  useEffect(() => {
    if (currentMenuState !== menuState) {
      currentMenuState = menuState

      if (menuState) {
        appear()
      } else {
        hide()
      }
    }
  }, [menuState])
  useEffect(() => {
    mount = true
  }, [])

  let category = useSelector(state => {
    return state.TransitionReducer.category
  })

  useEffect(() => {
    if (!category) return
    if (navItemRefs.current[category.slug] || category.slug === 'index') {
      for (let ref in navItemRefs.current) {
        if (!ref) return
        navItemRefs.current[ref].classList.remove('active')
      }
      if (category.slug !== 'index')
        navItemRefs.current[category.slug].classList.add('active')
      return
    }
  }, [category])

  //--------------------------------------
  //  Styles
  //--------------------------------------
  const contCss = () => {
    return css`
      transition: transform 1s cubic-bezier(0.71, 0.01, 0.45, 1.01);

      ${Libs.Styles.Mq.moreTab} {
        left: 50%;
        position: absolute;
        transform: translate(-50%, 100px);
        overflow: hidden;
        height: 3em;
      }
      ${Libs.Styles.Mq.lessPab} {
        position: absolute;
        width: 100%;
        margin-top: 100px;
        padding-bottom: 70px;
        display: none;
        z-index: 3;

        .on-menu &,
        .on-menu-complete & {
          display: block;
        }
      }

      .on-scrollinit:not(.on-story) & {
        ${Libs.Styles.Mq.moreTab} {
          transform: translate(-50%, 100px);
        }
      }
      .line {
        overflow: hidden;
        opacity: 0;
        height: 2px;
        background-color: #000;
        width: 88vw;
        margin-left: 6vw;
        margin-top: 2.5rem;
      }
    `
  }
  const navCss = () => {
    return css`
      ${Libs.Styles.Mq.moreTab} {
        height: 5em;
        width: 100%;
        overflow-x: auto;
        a-webkit-overflow-scrolling: touch;
      }
    `
  }
  const navListCss = () => {
    return css`
      margin: 0 auto;
      max-width: 100%;

      ${Libs.Styles.Mq.moreTab} {
        display: inline-table;
        li {
          display: inline-block;
          span {
            white-space: nowrap;
          }
          a {
            height: 3em;
            line-height: 3;
            .active a {
              pointer-events: none;
            }
            .active a:after {
              transform: scaleY(1);
              border-bottom: 3px solid ${Common.Config.keyColor};
            }
            &:after {
              content: ' ';
              position: absolute;
              left: 0;
              width: 100%;
              bottom: -10px;
              border-bottom: 3px solid #ececec;
              transform: scaleY(0);
              transform-origin: 0 100%;
              transition: all 0.9s cubic-bezier(0.19, 1, 0.22, 1);
            }
          }
          display: table-cell;
          &:first-child {
            a {
              ${Libs.Styles.Mq.moreTab} {
                margin-left: 0;
              }
              ${Libs.Styles.Mq.lessPab} {
                margin-left: 0;
              }
            }
          }
        }
      }
      ${Libs.Styles.Mq.lessPab} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 2rem;
        li {
          overflow: hidden;
          span {
            display: inline-block;
            position: relative;
            opacity: 0;
          }
        }
      }
      a {
        position: relative;
        text-decoration: none;
        color: #000;

        ${Libs.Common.Func.setVwValueByMq('margin-left', 6, 'xlarge', true)};
        ${Libs.Common.Func.setVwValueByMq('margin-left', 30, 'large', true)};
        ${Libs.Common.Func.setVwValueByMq('margin-left', 30, 'pc')};
        ${Libs.Common.Func.setVwValueByMq('margin-left', 20, 'tb')};
        ${Libs.Common.Func.setVwValueByMq('margin-left', 40, 'pab')};
        ${Libs.Common.Func.setVwValueByMq('margin-left', 30, 'sp')};
        cursor: pointer;
      }
    `
  }
  const appear = () => {
    const tl = gsap.timeline()
    const target = document.querySelector('.scroll-content')

    //if (!isMobile) contentScrollTop = target.getBoundingClientRect().top
    tl.set(
      '.global-nav li span,  .sns-list ul, .lang-dropdown span',

      {
        y: '-40',
        opacity: 1
      }
    )

      .to({}, 0.75, {})
      .to(
        '.global-nav li span,  .sns-list ul, .lang-dropdown span',
        {
          y: '0%',
          opacity: 1,
          duration: 0.5,
          ease: Power3.easeOut,
          onComplete: function () {
            const className = 'on-menu-complete'
            const cont = document.getElementsByTagName('html')[0]
            cont.classList.add(className)
          }
        },
        '-=.2'
      )
      .to(
        '.line',
        {
          opacity: 1,
          duration: 1.5,
          ease: Power3.easeOut
        },
        '<'
      )
  }
  const hide = () => {
    tl.to('.global-nav li span', {
      y: '-25',
      duration: 0.5,
      ease: Power3.easeOut,
      onComplete: function () {
        const className = 'on-menu-complete'
        const cont = document.getElementsByTagName('html')[0]
        cont.classList.remove(className)
      }
    }).to(
      '.line',
      {
        opacity: 0,
        duration: 0.5,
        ease: Power3.easeOut
      },
      '<'
    )

    //dispatch(Action.MenuClick(null, false))

    //
  }

  const clickHandler = e => {
    for (let ref in navItemRefs.current) {
      if (!ref) return
      navItemRefs.current[ref].classList.remove('active')
    }
    e.currentTarget.parentNode.classList.add('active')
    if (isMobile) {
      dispatch(Action.MenuClick(null, false))
      hide()
    }
  }
  //--------------------------------------
  //  Scripts
  //--------------------------------------

  /*
    const dataset = document
      .querySelector(".tl-wrapper")
      .querySelectorAll("section:first-child")[0].dataset
      */

  /*
  shouldComponentUpdate (nextProps, nextState) {
    if (nextProps.lang && langKey !== nextProps.lang) {
      langKey = nextProps.lang
      return true
    }

    const category = nextProps.category.slug

    if (navItemRefs[category] || category === 'index') {
      for (let ref in navItemRefs) {
        if (!ref) return
        navItemRefs[ref].classList.remove('active')
      }
      if (category !== 'index')
        navItemRefs[category].classList.add('active')
      return false
    }
  }
*/

  const navItems = Common.Config.getNavItems()
  const navItemRefs = useRef({})
  for (var i = 0; i < navItems.length; i++) {
    navItemRefs.current[navItems[i].slug] = React.createRef()
  }

  return (
    <div css={contCss} className='global-nav-cont'>
      <nav css={navCss} className='global-nav'>
        <ul css={navListCss}>
          {navItems.map((item, i) => (
            <li
              key={`navItem${i}`}
              /* onClick={props => clickHandler(props)}*/
              ref={el => {
                if (el) navItemRefs.current[item.slug] = el
              }}
            >
              <Libs.Atoms.TriggerLink
                transition={
                  Libs.Transition.TrigerPageTransition.TrigerPageTransition
                }
                to={`/${langKey}${item.to}/`}
                onClick={clickHandler}
              >
                <Libs.Atoms.Span
                  styles={{
                    fontPc: {
                      fontSize: 15,
                      lineHeight: 15,
                      letterSpacing: -0.216,
                      fontFace: 'serif'
                    },
                    fontSp: {
                      fontSize: 18,
                      lineHeight: 18,
                      letterSpacing: -0.216,
                      fontFace: 'serif'
                    }
                  }}
                >
                  {item.label}
                </Libs.Atoms.Span>
              </Libs.Atoms.TriggerLink>
            </li>
          ))}
        </ul>
      </nav>
      {isMobile ? <div className='line'></div> : ''}
    </div>
  )
}
