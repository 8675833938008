import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Action from 'libs/redux/action'
import * as Libs from 'libs'
import { css } from '@emotion/react'

class HamburgerMenu extends Component {
  constructor (props) {
    super(props)
    this.mount = false
  }
  onMenu = false
  className = 'on-menu'
  cont = null
  componentDidMount () {
    this.cont = document.getElementsByTagName('html')[0]
  }

  clickHandler = () => {
    if (!this.cont) return
    const { dispatch } = this.props

    this.onMenu = this.onMenu ? false : true

    dispatch(Action.MenuClick(this))
  }
  render () {
    const pStyle = {
      fontPc: {
        fontSize: 20,
        bold: true,
        letterSpacing: '-.5'
      },
      fontSp: {
        fontSize: 18,
        bold: true,
        letterSpacing: '-.5'
      }
    }
    const iconCss = type => {
      return css`
        display: inline-block;
        vertical-align: middle;
      `
    }

    return (
      <div onClick={this.clickHandler}>
        <Libs.Atoms.MenuIcon
          css={iconCss}
          className={'menu-icon'}
          width='24'
          height='24'
          iconW='18'
          iconH='12'
          type='rect'
        />
      </div>
    )
  }
}

export default connect()(HamburgerMenu)
