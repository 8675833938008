import React from 'react'
import { css } from '@emotion/react'

import * as Libs from 'libs'
import * as Common from '../../common'

export default props => {
  const { content, lang } = props

  const isArticle = false

  const headerContCss = () => css`
    .category-name {
      text-transform: uppercase;
    }
    > * {
      margin-top: 25px;
      text-align: center;
      :first-child {
        margin-top: 0px;
      }
    }
    margin: 0 auto;

    ${Libs.Styles.Mq.moreTab} {
      width: 50vw;
    }
    ${Libs.Styles.Mq.lessPab} {
      width: calc(100vw - 3em);
    }
    margin-bottom: 40px;
  `
  const infoContCss = () => css`
    display: flex;
    flex-direction: column;
    gap: 20px;

    ${Libs.Styles.Mq.moreTab} {
      padding-bottom: 40px;
    }
    ${Libs.Styles.Mq.lessPab} {
      padding-bottom: 20px;
    }
  `
  const linkListCss = () => css`
    margin-top: 25px;
    > * {
      margin-left: 0.5rem;
      :first-child {
        margin-left: 0px;
      }
    }
  `
  const buttonCss = () => css`
    display: inline-block;
    background-color: #000;
    cursor: pointer;
    border: none;
    outline: none;
    color: #fff !important;
    border-radius: 30px;
    padding: 1.0605rem 2rem;
    position: relative;
    z-index: 0;
    white-space: nowrap;
    pointer-events: auto;
    ${Libs.Styles.Mq.lessPab} {
      padding: 0.75rem 1.5rem;
    }
  `
  const tagsCss = props => {
    return css`
      display: flex;

      justify-content: center;
      ${Libs.Styles.Mq.moreTab} {
        gap: 1rem;
      }
      ${Libs.Styles.Mq.lessPab} {
        gap: 0.5rem;
      }
    `
  }

  const getCategoryName = content => {
    return content.tags
      ? content.tags.map(props => {
          if (props.group === 'Category') {
            return '> ' + props.name
          }
        })
      : ''
  }
  const getTagsElement = content => {
    return content.tags ? (
      <div css={tagsCss}>
        {content.tags.map(props => {
          if (props.group === 'Area' || props.group === 'Team') {
            return (
              <Libs.Atoms.TagButton
                to={`/${lang}/${props.group.toLowerCase()}/${props.slug}/`}
              >
                {props.name}
              </Libs.Atoms.TagButton>
            )
          }
        })}
      </div>
    ) : (
      ''
    )
  }
  const h1Css = props => {
    return css`
      ${Libs.Styles.Font.Serif()};
      ${Libs.Styles.Mq.moreTab} {
        html[lang='en'] & {
          font-size: 22px !important;
          line-height: 28px !important;
        }
        html[lang='ja'] & {
          font-size: 20px !important;
          line-height: 26px !important;
        }
      }
      ${Libs.Styles.Mq.lessPab} {
        html[lang='en'] & {
          font-size: 18px !important;
          line-height: 24px !important;
        }
        html[lang='ja'] & {
          font-size: 16px !important;
          line-height: 22px !important;
        }
      }
    `
  }

  const getTitleElement = content => {
    const style = {
      fontPc: {
        letterSpacing: -0.5,

        lineHeight: 32,
        fontFace: 'serif',
        italic: true
      },
      fontSp: {
        letterSpacing: -0.5,
        fontFace: 'serif',
        italic: true
      }
    }
    if (!isArticle) {
      style.fontPc.fontSize = 20
      style.fontSp.fontSize = 18
      style.fontSp.lineHeight = 24
    }
    return (
      <Libs.Atoms.H1 css={h1Css} styles={style}>
        {content.title}
      </Libs.Atoms.H1>
    )
  }
  let location
  if (content.links) {
    content.links.map((link, i) => {
      if (!location && link.location) {
        location = link.location
      }
    })
  }

  return (
    <div css={headerContCss}>
      <Libs.Atoms.H3
        className={'category-name'}
        styles={{
          fontPc: {
            fontSize: 12,
            lineHeight: 12,
            letterSpacing: 1,
            fontFace: 'sansserif',
            bold: true,
            color: Common.Func.getColor(content.category.slug)
          },
          fontSp: {
            fontSize: 12,
            lineHeight: 12,
            letterSpacing: 1,
            fontFace: 'sansserif',
            bold: true,
            color: Common.Func.getColor(content.category.slug)
          }
        }}
      >
        {content.category.name} {getCategoryName(content)}
      </Libs.Atoms.H3>
      <div css={infoContCss} className={'rt-cont'}>
        <Libs.Atoms.H1 className='content-title-for-info'>
          {content.name}
        </Libs.Atoms.H1>

        {content.links ? (
          <div css={linkListCss}>
            {content.links.map((link, i) => {
              return (
                <a className={'link-item'} href={link.url} target='_new'>
                  <Libs.Atoms.Icon type={link.icon} />
                </a>
              )
            })}
          </div>
        ) : (
          ''
        )}
        {location ? (
          <div>
            <Libs.Atoms.TriggerLink
              css={buttonCss}
              to={`/${content.node_locale}/map/`}
              onClick={() => {
                Common.Config.location = location
              }}
            >
              {content.node_locale === 'en' ? 'View on Map' : 'Mapで見る'}
            </Libs.Atoms.TriggerLink>
          </div>
        ) : (
          ''
        )}

        {getTagsElement(content)}
      </div>
      {getTitleElement(content)}
    </div>
  )
}
