import React from 'react'
import { css } from '@emotion/react'
import * as Modules from './'

import * as Libs from 'libs'
import * as Common from '../../common'
import * as Func from '../../common/func'

export default props => {
  const { content, lang } = props

  const listCont = (columnNum = 4) => {
    return css`
      margin-left: max(4em, calc((100vw - 1600px + 8em) / 2));
      width: calc(100vw - 8em);
      max-width: calc(1600px - 8em);
      ${Libs.Styles.Mq.lessTab} {
        width: calc(100vw - 3em);
        margin-left: max(1.5em, calc((100vw - 1600px + 3em) / 2));
      }
      border-top: 1px solid #000;
      padding-top: 3rem;
      margin-top: 3rem;
      display: grid;

      --grid-gap: ${columnNum === 2
        ? '8rem'
        : columnNum === 3
        ? '5rem'
        : '2rem'};
      --grid-margin: 3rem;
      column-gap: var(--grid-gap);
      row-gap: var(--grid-margin);
      grid-auto-rows: min-content;

      ${Libs.Styles.Mq.moreTab} {
        margin-top: 10rem;
        grid-template-columns: repeat(${columnNum}, 1fr);
        grid-column: 1 / -1;
      }

      ${Libs.Styles.Mq.lessPab} {
        padding-top: 2rem;
        margin-top: 6rem;
        grid-template-columns: 1fr;
        --grid-gap: 1rem;
        --grid-margin: 2rem;
        > * {
          grid-column: 1 / -1;
        }
      }
      h4 {
        grid-column: 1 / -1;
        ${Libs.Styles.Font.Serif()};
        text-align: center;
      }
    `
  }

  let postData = Func.getData(lang)
  postData = postData.filter(function (value) {
    if (content.id !== value.id) {
      return true
    }
  })
  const relatedData = postData
    .filter(item => {
      return item.category.slug === content.category.slug ? true : false
    })
    .slice(0, 4)

  return (
    <div css={[listCont(4)]}>
      <Libs.Atoms.H4>Related Contents</Libs.Atoms.H4>
      <Modules.ImageCardList column='row' items={relatedData} type={'index'} />
    </div>
  )
}
