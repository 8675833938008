import React, { PureComponent } from 'react'
import { css } from '@emotion/react'

import * as Transition from '../Transition'
import { connect } from 'react-redux'
import * as Libs from 'libs'

class Breadcrumb extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      ready: false
    }
  }
  componentDidMount () {
    let { current } = this.props
    this.init = false
    const section = document.querySelectorAll('section:first-child')
    this.dataset = section[0] ? section[0].dataset : null
    this.setState({ ready: true })
  }

  render () {
    if (!this.state.ready || !this.dataset) return null
    let count = 1
    let { current, prev, next, category, pageType } = this.props

    if (!current) {
      let locationHref = window.location.href

      var pos = locationHref.indexOf('?')
      if (pos < 0) {
      } else {
        locationHref = locationHref.substring(0, pos)
      }

      const filename = Libs.Common.Func.getFileName(locationHref)

      prev = this.dataset.prev
      next = this.dataset.next

      current = this.dataset.currentname
        ? { name: this.dataset.currentname, slug: this.dataset.current }
        : { name: Libs.Common.Func.toUpperCaseFiest(filename), slug: filename }
      category = this.dataset.categoryname
        ? {
            name: this.dataset.categoryname,
            slug: this.dataset.categoryslug
          }
        : null
    }

    let langKey = Libs.Common.Func.getLangKey()
    const list = []
    const breakPoints = [413, 768, 960, 1280, 1280]
    const cont = document.querySelector('html')

    if (
      !cont.classList.contains('type-post') ||
      !cont.classList.contains('type-page.category-map')
    )
      list.push(current)

    const contCss = css`
      position: relative;

      text-align: center;
      margin-left: auto;
      margin-right: auto;
      background-color: #fff;
      padding-top: 30px;
      padding-bottom: 30px;
      transform: translateY(140px);
      transition: transform 1s, all 0.5s cubic-bezier(0.71, 0.01, 0.45, 1.01);
      ${Libs.Styles.Mq.lessPab} {
        transform: translateY(64px);
        padding-top: 15px;
        padding-bottom: 25px;

        .type-post & {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .type-category.on-scrollinit &,
      .type-post &,
      .category-map & {
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .type-page.category-map &,
      .type-post & {
        &:after {
          content: ' ';
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          border-bottom: 1px solid #ccc;
          bottom: 0px;

          ${Libs.Styles.Mq.moreTab} {
            width: calc(100vw - 8em);
            max-width: calc(1600px - 8em);
          }
          ${Libs.Styles.Mq.lessPab} {
            width: calc(100vw - 3em);
          }
        }

        ${Libs.Styles.Mq.moreTab} {
          transform: translateY(142px);
        }
        ${Libs.Styles.Mq.lessPab} {
          transform: translateY(64px);
        }
      }

      .now-transition & {
        opacity: 0;
      }
      .on-scrollinit & {
        ${Libs.Styles.Mq.moreTab} {
          transform: translateY(140px);
        }
      }
    `
    const prevCss = css`
      display: inline-block;

      &:after {
        display: inline-block;
        content: '|';
        color: #000;
        ${Libs.Styles.Mq.moreTab} {
          margin-left: ${Libs.Common.Func.getVwValue(0.5)}vw;
          margin-right: ${Libs.Common.Func.getVwValue(0.5)}vw;
        }
        ${Libs.Styles.Mq.lessTab} {
          margin-left: ${Libs.Common.Func.getVwValue(2)}vw;
          margin-right: ${Libs.Common.Func.getVwValue(2)}vw;
        }
        position: relative;
      }
    `
    const nextCss = css`
      display: inline-block;

      &:before {
        display: inline-block;
        content: '|';
        color: #000;
        ${Libs.Styles.Mq.moreTab} {
          margin-left: ${Libs.Common.Func.getVwValue(0.5)}vw;
          margin-right: ${Libs.Common.Func.getVwValue(0.5)}vw;
        }
        ${Libs.Styles.Mq.lessTab} {
          margin-left: ${Libs.Common.Func.getVwValue(2)}vw;
          margin-right: ${Libs.Common.Func.getVwValue(2)}vw;
        }

        position: relative;
      }
    `
    const ulCss = css`
      display: inline-block;
      li {
        display: inline-block;

        ${Libs.Styles.Mq.moreTab} {
        }
        ${Libs.Styles.Mq.lessPab} {
          margin-left: ${Libs.Common.Func.getVwValue(2)}vw;
          margin-right: ${Libs.Common.Func.getVwValue(2)}vw;
          .type-post & {
            margin-left: 0;
            margin-right: ${Libs.Common.Func.getVwValue(5)}vw;
            &:last-child {
              margin-right: 0px;
            }
            span {
              display: inline-block;
              max-width: 80px; /* 省略せずに表示するサイズを指定 */
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        &:after {
          .on-story & {
            color: #fff;
          }
          content: '>';

          ${Libs.Styles.Mq.moreTab} {
            margin-left: ${Libs.Common.Func.getVwValue(0.5)}vw;
            margin-right: ${Libs.Common.Func.getVwValue(0.5)}vw;
          }
          ${Libs.Styles.Mq.tb} {
            margin-left: ${Libs.Common.Func.getVwValue(1)}vw;
            margin-right: ${Libs.Common.Func.getVwValue(1)}vw;
          }
          ${Libs.Styles.Mq.lessPab} {
            margin-left: ${Libs.Common.Func.getVwValue(2)}vw;
            margin-right: ${Libs.Common.Func.getVwValue(2)}vw;
            .type-post & {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              margin-left: 2px;
            }
          }
        }

        .type-category &:last-child,
        .category-contact &:last-child {
          transition: all 0.5s cubic-bezier(0.71, 0.01, 0.45, 1.01);
          display: block;
          span {
            transition: all 0.5s cubic-bezier(0.71, 0.01, 0.45, 1.01);
            pointer-events: none;
            max-width: auto; /* 省略せずに表示するサイズを指定 */
            ${Libs.Common.Func.getPcSpVwValue('font-size', 54, 42, true)};
            ${Libs.Common.Func.getPcSpVwValue('line-height', 54, 42, true)};
          }
        }

        .on-scrollinit.type-category &:last-child {
        }
        .on-scroll.type-category &:last-child {
          opacity: 1;
          display: inline-block;

          span {
            margin-top: 0px;
            ${Libs.Common.Func.getPcSpVwValue('font-size', 15, 14, true)};
            line-height: 36px;
          }
        }
        &:last-child {
          margin-left: 0;
          &:after {
            content: '';
            margin-left: 0;
          }
        }
      }
    `
    const getPagingStyleObject = props => {
      return {
        fontPc: { fontSize: 13, lineHeight: 36, bold: true },
        fontSp: { fontSize: 13, lineHeight: 36, bold: true }
      }
    }
    const getStyleObject = props => {
      return {
        fontPc: { fontSize: 15, lineHeight: 36, fontFace: 'serif' },
        fontSp: { fontSize: 14, lineHeight: 36, fontFace: 'serif' }
      }
    }
    const getCagegory = props => {
      if (category != null && pageType === 'post') {
        count++
        return (
          <>
            <li
              itemprop='itemListElement'
              itemscope
              itemtype='http://schema.org/ListItem'
            >
              <Libs.Atoms.TriggerLink
                transition={
                  Libs.Transition.TrigerPageTransition.TrigerPageTransition
                }
                to={`/${langKey}/${category.slug}/`}
              >
                <Libs.Atoms.Span
                  className={'bc-button'}
                  itemProp='name'
                  styles={getStyleObject()}
                >
                  {category.name}
                </Libs.Atoms.Span>
              </Libs.Atoms.TriggerLink>
            </li>
            <meta itemprop='position' content={count} />
          </>
        )
      }
    }
    const getPrevElement = props => {
      if (prev != null) {
        return (
          <div css={prevCss}>
            <Libs.Atoms.TriggerLink
              to={`/${langKey}/${category.slug}/${prev}/`}
            >
              <Libs.Atoms.Span
                className={'bc-button'}
                styles={getPagingStyleObject()}
              >
                PREV{' '}
              </Libs.Atoms.Span>
            </Libs.Atoms.TriggerLink>
          </div>
        )
      }
    }
    const getNextElement = props => {
      if (next != null) {
        return (
          <div css={nextCss}>
            <Libs.Atoms.TriggerLink
              to={`/${langKey}/${category.slug}/${next}/`}
            >
              <Libs.Atoms.Span
                className={'bc-button'}
                styles={getPagingStyleObject()}
              >
                NEXT
              </Libs.Atoms.Span>
            </Libs.Atoms.TriggerLink>
          </div>
        )
      }
    }

    if (
      current.slug == 'index' ||
      current.slug === 'ja' ||
      current.slug === 'en'
    ) {
      return <></>
    }

    return (
      <div css={contCss}>
        {getPrevElement()}

        <ol itemscope itemtype='http://schema.org/BreadcrumbList' css={ulCss}>
          <li
            itemprop='itemListElement'
            itemscope
            itemtype='http://schema.org/ListItem'
          >
            <Libs.Atoms.TriggerLink
              transition={
                Libs.Transition.TrigerPageTransition.TrigerPageTransition
              }
              to={`/${langKey}`}
            >
              <Libs.Atoms.Span
                className={'bc-button'}
                itemProp='name'
                styles={getStyleObject()}
              >
                Home
              </Libs.Atoms.Span>
            </Libs.Atoms.TriggerLink>
            <meta itemprop='position' content={count} />
          </li>
          {getCagegory()}
          {list.map((item, i) => {
            return (
              <li
                itemprop='itemListElement'
                itemscope
                itemtype='http://schema.org/ListItem'
                key={`breadcrumbItem${i}`}
              >
                <Libs.Atoms.TriggerLink
                  transition={
                    Libs.Transition.TrigerPageTransition.TrigerPageTransition
                  }
                  to={`/${langKey}/${category ? category.slug + '/' : ''}${
                    item.slug
                  } `}
                >
                  <Libs.Atoms.Span
                    className={'bc-button'}
                    itemProp='name'
                    styles={getStyleObject()}
                  >
                    {item.name}
                  </Libs.Atoms.Span>
                </Libs.Atoms.TriggerLink>
                <meta itemprop='position' content={i + 1 + count} />
              </li>
            )
          })}
        </ol>
        {getNextElement()}
      </div>
    )
  }
}

export default connect(state => ({
  current: state.TransitionReducer.current,
  pageType: state.TransitionReducer.pageType,
  category: state.TransitionReducer.category,
  prev: state.TransitionReducer.prev,
  next: state.TransitionReducer.next
}))(Breadcrumb)
