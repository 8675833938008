import React, { Component } from "react";
import { css } from "@emotion/react";

import * as Libs from "libs";
class Symbol extends Component {
	render() {
		return (
			<Libs.Atoms.TriggerLink
				transition={Libs.Transition.TrigerPageTransition.TrigerPageTransition}
				to={`/`}
			>
				<svg
					css={style}
					viewBox="0 0 73 100"
					preserveAspectRatio="xMinYMin meet"
				>
					<use xlinkHref="#symbol" x="0" y="0" />
				</svg>
			</Libs.Atoms.TriggerLink>
		);
	}
}
const style = () => css`
	height: 5vw;
	max-height: 25px;

	${Libs.Styles.Mq.lessPab} {
		height: 7vw;
		min-height: 30px;
	}
`;
export default Symbol;
