import React from 'react'

import * as Atoms from '../atoms'
export default props => {
  const { items, slug, column, type, description, ...rest } = props

  return (
    <>
      {items &&
        items.map((item, i) => {
          if (item.slug === 'dummyPost') {
            return ''
          } else if (
            type === 'tags' ||
            (!item.thumbnail && !item.thumbnailSp) ||
            column === 'row'
          ) {
            return <Atoms.ImageCardRow item={item} type={type} />
          } else {
            return <Atoms.ImageCard item={item} type={type} />
          }
        })}
    </>
  )
}
