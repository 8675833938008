import React from 'react'
import { css } from '@emotion/react'
import * as Hooks from '../../hooks'
import * as Func from '../../libs/common/func'

import * as Styles from '../../libs/styles'
import { connect } from 'react-redux'
import * as Libs from 'libs'
export const TagFilter = props => {
  const contCss = () =>
    css`
      background-color: #fff;
      &:after,
      &:before {
        content: ' ';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      &:before {
        top: 0px;
        border-top: 1px solid #ccc;
      }
      &:after {
        bottom: 0px;
        border-bottom: 1px solid #000;
      }
      ${Libs.Styles.Mq.moreTab} {
        z-index: 100;
        &:after,
        &:before {
          width: calc(100vw - 8em);
          max-width: calc(1600px - 8em);
        }
      }
      ${Libs.Styles.Mq.lessTab} {
        &:after,
        &:before {
          width: calc(100vw - 3em);
          max-width: calc(1600px - 3em);
        }
      }
      position: relative;
      width: 100%;
      transition: all 1s cubic-bezier(0.71, 0.01, 0.45, 1.01);

      > div {
        position: relative;

        display: flex;
        justify-content: center;
        > div {
          margin: 0 1rem 0 1rem;
        }
      }
      span {
        line-height: 36px !important;
      }
      /*  .init-loaded & {
      }*/

      ${Styles.Mq.moreTab} {
        transform: translateY(140px);
      }

      ${Styles.Mq.lessPab} {
        transform: translateY(64px);
      }

      .now-transition &,
      .category-index &,
      .category-contact &,
      .type-page.category-map &,
      .type-post & {
        transition: opacity 0.5s cubic-bezier(0.71, 0.01, 0.45, 1.01);
        opacity: 0;
        pointer-events: none;
      }
      a .on-scroll.on-scrolldown & {
        ${Styles.Mq.lessPab} {
          opacity: 0;
        }
      }

      > div {
        &:not(:first-child) {
          margin-left: 4vw;
        }
      }
    `
  const {
    children,
    className,
    forwardedref,
    current,

    ...rest
  } = props

  const category = props.category ? props.category.slug : null
  const langKey = Libs.Common.Func.getLangKey()

  let data =
    category == 'food-and-drink'
      ? langKey === 'en'
        ? Hooks.FoodDrinkCategoryTagDataEn.FoodDrinkCategoryTagDataEn()
        : Hooks.FoodDrinkCategoryTagData.FoodDrinkCategoryTagData()
      : category == 'fashion'
      ? langKey === 'en'
        ? Hooks.FashionCategoryTagDataEn.FashionCategoryTagDataEn()
        : Hooks.FashionCategoryTagData.FashionCategoryTagData()
      : category == 'living'
      ? langKey === 'en'
        ? Hooks.LivingCategoryTagDataEn.LivingCategoryTagDataEn()
        : Hooks.LivingCategoryTagData.LivingCategoryTagData()
      : category == 'art-culture'
      ? langKey === 'en'
        ? Hooks.ArtCategoryTagDataEn.ArtCategoryTagDataEn()
        : Hooks.ArtCategoryTagData.ArtCategoryTagData()
      : category == 'sports'
      ? Hooks.SportsCategoryTagData.SportsCategoryTagData()
      : category == 'area'
      ? Hooks.AllTagData.AllTagData()
      : null

  const items =
    data && category != 'area'
      ? data['content___post']
      : data
      ? data.edges
      : null

  const tagsMap = new Map()
  if (items && category != 'area') {
    items.map((item, i) => {
      if (item.tags) {
        item.tags.map(tag => {
          if (!tagsMap.has(tag.group)) {
            const newMap = new Map()
            newMap.set(tag.slug, tag, category)
            tag.category = category
            tagsMap.set(tag.group, newMap)
          } else {
            const tmp = tagsMap.get(tag.group)
            tag.category = category
            //console.log("tagsMap---", tag, tmp.has(tag.slug))

            tmp.set(tag.slug, tag)
          }
        })
      }
    })
  } else {
    if (items)
      items.map((item, i) => {
        const tag = item.node
        if (!tagsMap.has(tag.group)) {
          const newMap = new Map()
          newMap.set(tag.slug, tag, category)
          tag.category = category
          tagsMap.set(tag.group, newMap)
        } else {
          const tmp = tagsMap.get(tag.group)
          tag.category = category
          //console.log("tagsMap---", tag, tmp.has(tag.slug))

          tmp.set(tag.slug, tag)
        }
      })
  }

  return (
    <div css={contCss} className='tag-filter'>
      <div>
        {items
          ? Object.entries(Func.mapToObject(tagsMap)).map((item, i) => {
              const currentMap = item[1].get(current.slug)
              return (
                <div>
                  <Libs.Modules.SelectHeader
                    header={item[0]}
                    values={item[1]}
                    category={item[0].toLowerCase()}
                    current={currentMap ? currentMap.slug : null}
                    headerStyle={{
                      fontPc: {
                        italic: true,
                        fontSize: 14,
                        lineHeight: 36,
                        letterSpacing: 0.43,
                        fontFace: 'serif'
                      },
                      fontSp: {
                        italic: true,
                        fontSize: 14,
                        lineHeight: 36,
                        letterSpacing: 0.43,
                        fontFace: 'serif'
                      },
                      display: 'inline'
                    }}
                    selectStyle={{
                      fontPc: {
                        fontSize: 14,
                        lineHeight: 36,
                        letterSpacing: 0.43,
                        fontFace: 'serif'
                      },
                      fontSp: {
                        fontSize: 14,
                        lineHeight: 36,
                        letterSpacing: 0.43,
                        fontFace: 'serif'
                      }
                    }}
                  />
                </div>
              )
            })
          : ''}
      </div>
    </div>
  )
}
export default connect(state => ({
  current: state.TransitionReducer.current,
  category: state.TransitionReducer.category,
  prev: state.TransitionReducer.prev,
  next: state.TransitionReducer.next
}))(TagFilter)
