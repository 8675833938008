import React from 'react'
import { css } from '@emotion/react'
import * as Libs from 'libs'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as Atoms from '.'
import * as Transition from '../Transition'
import { WindowResize } from 'libs/redux/event/WindowResize'
export default props => {
  const { item, type } = props
  const imgcardCont = () => {
    return css`
      column-gap: var(--grid-gap);
      row-gap: var(--grid-gap);
      row-gap: var(--grid-gap);

      padding-bottom: 2rem;

      ${Libs.Styles.Mq.moreTab} {
        --grid-gap: 2rem;
        --grid-margin: 4rem;
        border-top: none;
        .category-sports & {
          &:nth-child(n + 5) {
            border-top: 1px solid #000;
            padding-top: 2rem;
          }
        }
      }
      ${Libs.Styles.Mq.lessPab} {
        --grid-gap: 1rem;
        border-top: ${!imgSrc ? `1px solid #000` : ''};
        ${!imgSrc ? 'padding-top: 2rem;' : ''}

        --grid-margin: 1.5rem;
      }
    `
  }
  const imgLinkCss = props => {
    const { slug } = props

    return css`
      display: block;
      margin-block-end: 2rem;
    `
  }
  const imgCss = props => {
    return css`
      height: 100%;
    `
  }
  const gridText = props => {
    return css`
      ${Libs.Styles.Mq.morePc} {
        align-self: center;
        justify-self: center;
      }
      ${Libs.Styles.Mq.lessTab} {
      }
    `
  }
  const imgSrc =
    WindowResize.currentType(WindowResize.lessPab) && item.thumbnailSp
      ? item.thumbnailSp
      : item.thumbnail

  return (
    <article css={imgcardCont}>
      {imgSrc ? (
        <Libs.Atoms.TriggerLink
          css={imgLinkCss}
          to={`/${item.node_locale}/${item.category.slug}/${item.slug}/`}
        >
          <div css={imgCss} className={'img-cont img-element'}>
            <GatsbyImage
              css={imgCss}
              loading='eager'
              image={imgSrc.gatsbyImageData}
            />
          </div>
        </Libs.Atoms.TriggerLink>
      ) : (
        ''
      )}
      <div css={gridText} lassName={'text-element'}>
        <Atoms.ImageCardDescription item={item}></Atoms.ImageCardDescription>
      </div>
    </article>
  )
}
