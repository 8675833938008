import { useStaticQuery, graphql } from 'gatsby'

export const FoodDrinkCategoryTagData = () => {
  const { contentfulCommonCategory } = useStaticQuery(
    graphql`
      query CommonFoodDrinkCategoryJaQuery {
        contentfulCommonCategory(
          content___post: {
            elemMatch: {
              node_locale: { eq: "ja" }
              category: { slug: { eq: "food-and-drink" } }
            }
          }
        ) {
          node_locale
          slug
          content___post {
            tags {
              name
              slug
              group
            }
          }
        }
      }
    `
  )
  return contentfulCommonCategory
}
