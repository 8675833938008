import React, { Component } from 'react'
import { css } from '@emotion/react'
import * as Libs from 'libs'

class InitialLoader extends Component {
  constructor (props) {
    super(props)

    this.container = React.createRef()
  }

  componentDidMount () {}

  render () {
    const containerCss = () => {
      return css`
        .spinner {
          width: 140px;
          height: 12px;
          position: fixed;
          top: 50%;
          left: 50%;
          margin-left: -70px;
          margin-top: -20px;
          text-align: center;
          font-size: 10px;
          opacity: 0;
          z-index: 100000;
          transition: opacity 0.3s ease-out;
          -moz-transform: scale(0.6);
          -o-transform: scale(0.6);
          -ms-transform: scale(0.6);
          -webkit-transform: scale(0.6);
          transform: scale(0.6);
          .init-load &,
          .page-load & {
            opacity: 1;
            transition: opacity 0.3s ease-out;
          }
          div:not(.o) {
            background-color: #000;
            position: absolute;
          }
        }

        @-webkit-keyframes sk-stretchdelay {
          0%,
          40%,
          100% {
            -webkit-transform: scaleY(1);
          }
          20% {
            -webkit-transform: scaleY(1.4);
          }
        }

        @keyframes sk-stretchdelay {
          0%,
          40%,
          100% {
            transform: scaleY(1);
            -webkit-transform: scaleY(1);
          }
          20% {
            transform: scaleY(1.4);
            -webkit-transform: scaleY(1.4);
          }
        }
      `
    }
    const style = () => css`
      height: 5vw;
      min-height: 80px;
      max-height: 100px;

      ${Libs.Styles.Mq.lessPab} {
        height: 7vw;
        min-height: 70px;
      }
    `

    return (
      <div css={containerCss}>
        <div className='spinner'>
          <svg
            css={style}
            viewBox='0 0 73 100'
            preserveAspectRatio='xMinYMin meet'
          >
            <use xlinkHref='#symbol' x='0' y='0' />
          </svg>
        </div>
      </div>
    )
  }
}

export default InitialLoader
