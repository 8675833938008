import React from 'react'
import { css } from '@emotion/react'
import * as Common from '../../common'
import * as Transition from '../Transition'
import * as Libs from 'libs'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

export default props => {
  const { item, lineCount = 2, lineCountSp = 2 } = props

  const h2Css = props => {
    return css`
      margin-block-end: 1rem;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      letter-spacing: -0.5;
      ${Libs.Styles.Font.Serif()};
      html[lang='en'] & {
        ${Libs.Common.Func.setPcVwValues('font-size', 22, true)};
        ${Libs.Common.Func.setPcVwValues('line-height', 29, true)};
        ${Libs.Common.Func.setSpVwValues('font-size', 21, true)};
        ${Libs.Common.Func.setSpVwValues('line-height', 29, true)};
      }
      html[lang='ja'] & {
        ${Libs.Common.Func.setPcVwValues('font-size', 19, true)};
        ${Libs.Common.Func.setPcVwValues('line-height', 26, true)};
        ${Libs.Common.Func.setSpVwValues('font-size', 19, true)};
        ${Libs.Common.Func.setSpVwValues('line-height', 26, true)};
      }
    `
  }
  const tagsCont = props => {
    return css`
      margin-top: 1.2rem;
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
      p {
        white-space: nowrap;
      }
    `
  }
  const linkContCss = props => {
    return css`
      margin-top: 0.85rem;
      .link-item {
        margin-right: 0.5rem;
      }
    `
  }
  const catCss = props => {
    return css`
      text-transform: uppercase;
      margin-bottom: 15px;
    `
  }

  const contentCaptionCss = () => {
    return css`
      p {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: ${lineCount};
        -webkit-box-orient: vertical;
        color: #4d4d4d;

        ${Libs.Styles.Mq.lessPc} {
          -webkit-line-clamp: ${lineCountSp};
        }
      }
    `
  }

  const cat = item.tags
    ? item.tags.filter(props => {
        if (props.group === 'Category' || props.group === 'Type') {
          return true
        } else return false
      })
    : null

  return (
    <>
      <Libs.Atoms.H3
        css={catCss}
        styles={{
          fontPc: {
            fontSize: 12,
            lineHeight: 12,
            letterSpacing: 1,
            fontFace: 'sansserif',
            bold: true,
            color: Common.Func.getColor(item.category.slug)
          },
          fontSp: {
            fontSize: 12,
            lineHeight: 12,
            letterSpacing: 1,
            fontFace: 'sansserif',
            bold: true,
            color: Common.Func.getColor()
          }
        }}
      >
        {item.category.name} {cat > 0 ? ` / ${cat[0].name}` : ''}
      </Libs.Atoms.H3>
      <Libs.Atoms.TriggerLink
        to={`/${item.node_locale}/${item.category.slug}/${item.slug}/`}
        transition={Libs.Transition.TrigerPageTransition.TrigerPageTransition}
      >
        <Libs.Atoms.H2 css={h2Css}>
          {item.headLine ? item.headLine[0].text : item.title}
        </Libs.Atoms.H2>
      </Libs.Atoms.TriggerLink>
      <Libs.Atoms.TriggerLink
        to={`/${item.node_locale}/${item.category.slug}/${item.slug}/`}
        transition={Libs.Transition.TrigerPageTransition.TrigerPageTransition}
      >
        {item.description ? (
          <Libs.Atoms.RichText
            css={contentCaptionCss}
            className={'content-caption'}
          >
            {item.description}
          </Libs.Atoms.RichText>
        ) : (
          ''
        )}
      </Libs.Atoms.TriggerLink>
      {item.tags ? (
        <div css={tagsCont}>
          {item.tags.map(props => {
            if (props.group === 'Area' || props.group === 'Team') {
              return (
                <Libs.Atoms.TagButton
                  to={`/${item.node_locale}/${props.group.toLowerCase()}/${
                    props.slug
                  }/`}
                >
                  {props.name}
                </Libs.Atoms.TagButton>
              )
            }
          })}
        </div>
      ) : (
        ''
      )}
      {item.links ? (
        <div css={linkContCss}>
          {item.links.map((link, i) => {
            return (
              <a className={'link-item'} href={link.url} target='_new'>
                <Libs.Atoms.Icon type={link.icon} />
              </a>
            )
          })}
        </div>
      ) : (
        ''
      )}
    </>
  )
}
