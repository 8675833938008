import React, { PureComponent } from 'react'
import { elementHoc } from './ElementHoc'
import { css } from '@emotion/react'
import * as Styles from '../styles'
export class GridElement extends PureComponent {
  render () {
    let {
      className,
      forwardedref,
      children,
      data,

      ...rest
    } = this.props

    const stylesObj = {
      display: 'grid'
    }

    const getDefaultGridJson = () => {
      const gridColumnsPc = [...Array(50).keys()].map(i => '3 / 11')
      const gridColumnsSp = [...Array(50).keys()].map(i => '1 / -1')
      return {
        pc: {
          //gridTemplateColumns: '1fr 1fr 1fr 1fr',
          gridTemplateColumns: 'repeat(12, 1fr)',
          columnGap: '1rem',
          rowGap: '4rem',
          gridColumns: gridColumnsPc

          //gridColumns: ['1 / 3', '4 / 5', '1 / 2', '2 /3', '3 / 5']
        },
        sp: {
          //gridTemplateColumns: '1fr 1fr 1fr 1fr',
          gridTemplateColumns: 'repeat(12, 1fr)',
          columnGap: '1rem',
          rowGap: '4rem',
          gridColumns: gridColumnsSp

          //gridColumns: ['1 / 3', '4 / 5', '1 / 2', '2 /3', '3 / 5']
        }
      }
    }

    let gridJson = data.instance.styles.gridJson
      ? data.instance.styles.gridJson
      : getDefaultGridJson()

    const gridTemplateColumnsPc =
      gridJson.pc && gridJson.pc.gridTemplateColumns
        ? `grid-template-columns: ${gridJson.pc.gridTemplateColumns};`
        : ''
    const gridTemplateColumnsSp =
      gridJson.sp && gridJson.sp.gridTemplateColumns
        ? `grid-template-columns: ${gridJson.sp.gridTemplateColumns};`
        : ''
    const gridAutoFlowPc =
      gridJson.pc && gridJson.pc.gridAutoFlow
        ? `grid-auto-flow:  ${gridJson.pc.gridAutoFlow};`
        : ''
    const gridAutoFlowSp =
      gridJson.sp && gridJson.sp.gridAutoFlow
        ? `grid-auto-flow:  ${gridJson.sp.gridAutoFlow};`
        : ''

    const gridTemplateRowsPc =
      gridJson.pc && gridJson.pc.gridTemplateColumns
        ? `grid-template-rows: ${gridJson.pc.gridTemplateRows};`
        : ''
    const gridTemplateRowsSp =
      gridJson.sp && gridJson.sp.gridTemplateColumns
        ? `grid-template-rows: ${gridJson.sp.gridTemplateRows};`
        : ''

    const columnGapPc =
      gridJson.pc && gridJson.pc.columnGap
        ? `column-gap: ${gridJson.pc.columnGap};`
        : ''
    const columnGapSp =
      gridJson.sp && gridJson.sp.columnGap
        ? `column-gap: ${gridJson.sp.columnGap};`
        : ''
    const rowGap =
      gridJson.pc && gridJson.pc.rowGap ? `row-gap: ${gridJson.pc.rowGap};` : ''
    const rowGapSp =
      gridJson.sp && gridJson.sp.rowGap ? `row-gap: ${gridJson.sp.rowGap};` : ''

    const gridCss = () => {
      const gridColumnsPc =
        gridJson.pc && gridJson.pc.gridColumns ? gridJson.pc.gridColumns : ''
      const gridRowsPc =
        gridJson.pc && gridJson.pc.gridRows ? gridJson.pc.gridRows : ''
      const alignSelfPc =
        gridJson.pc && gridJson.pc.alignSelf ? gridJson.pc.alignSelf : ''
      const justifySelfPc =
        gridJson.pc && gridJson.pc.justifySelf ? gridJson.pc.justifySelf : ''
      const zIndexPc =
        gridJson.pc && gridJson.pc.zIndex ? gridJson.pc.zIndex : ''

      const gridColumnsSp =
        gridJson.sp && gridJson.sp.gridColumns ? gridJson.sp.gridColumns : ''
      const gridRowsSp =
        gridJson.sp && gridJson.sp.gridRows ? gridJson.sp.gridRows : ''
      const alignSelfSp =
        gridJson.sp && gridJson.sp.alignSelf ? gridJson.sp.alignSelf : ''
      const justifySelfSp =
        gridJson.sp && gridJson.sp.justifySelf ? gridJson.sp.justifySelf : ''
      const zIndexSp =
        gridJson.sp && gridJson.sp.zIndex ? gridJson.sp.zIndex : ''

      return css`
        display: grid;

        ${Styles.Mq.moreTab} {
          ${gridTemplateColumnsPc}
          ${gridTemplateRowsPc}
          ${gridAutoFlowPc}
          ${columnGapPc}
          ${rowGap}

          ${gridColumnsPc
            ? gridColumnsPc.map((gridColumn, i) => {
                return `>*:nth-child(${i + 1}) {
                  grid-column: ${gridColumn};
                  };`
              })
            : ''}
            ${gridRowsPc
            ? gridRowsPc.map((gridRow, i) => {
                return `>*:nth-child(${i + 1}) {
                  grid-row: ${gridRow};
                };`
              })
            : ''}
                ${alignSelfPc
            ? alignSelfPc.map((alignSelf, i) => {
                if (alignSelf) {
                  return `>*:nth-child(${i + 1}) {
                  align-self: ${alignSelf};
                };`
                } else {
                  return ``
                }
              })
            : ''}
                ${justifySelfPc
            ? justifySelfPc.map((justifySelf, i) => {
                if (justifySelf) {
                  return `>*:nth-child(${i + 1}) {
                  justify-self: ${justifySelf};
                };`
                } else {
                  return ``
                }
              })
            : ''}
            ${zIndexPc
            ? zIndexPc.map((zIndex, i) => {
                if (zIndex) {
                  return `>*:nth-child(${i + 1}) {
                  z-index: ${zIndex};
                };`
                } else {
                  return ``
                }
              })
            : ''}
        }
        ${Styles.Mq.lessPab} {
          ${gridTemplateColumnsSp};
          ${gridTemplateRowsSp}
          ${gridAutoFlowSp}
          ${columnGapSp}
          ${rowGapSp}
          ${gridColumnsSp
            ? gridColumnsSp.map((gridColumn, i) => {
                return `>*:nth-child(${i + 1}) {
                  grid-column: ${gridColumn};
                  };`
              })
            : ''}
            ${gridRowsSp
            ? gridRowsSp.map((gridRow, i) => {
                return `>*:nth-child(${i + 1}) {
                  grid-row: ${gridRow};
                };`
              })
            : ''}
                ${alignSelfSp
            ? alignSelfSp.map((alignSelf, i) => {
                if (alignSelf) {
                  return `>*:nth-child(${i + 1}) {
                  align-self: ${alignSelf};
                };`
                } else {
                  return ``
                }
              })
            : ''}
                ${justifySelfSp
            ? justifySelfSp.map((justifySelf, i) => {
                if (justifySelf) {
                  return `>*:nth-child(${i + 1}) {
                  justify-self: ${justifySelf};
                };`
                } else {
                  return ``
                }
              })
            : ''}
            ${zIndexSp
            ? zIndexSp.map((zIndex, i) => {
                if (zIndex) {
                  return `>*:nth-child(${i + 1}) {
                  z-index: ${zIndex};
                };`
                } else {
                  return ``
                }
              })
            : ''}
        }
      `
    }
    return (
      <div
        ref={forwardedref}
        css={data.func(stylesObj, data.instance, gridCss)}
        className={`${className ? className : ''} grid-cont`}
        {...rest}
      >
        {children}
      </div>
    )
  }
}

export default elementHoc(GridElement)
