// src/components/googleAdsense.js

import React, { useEffect } from 'react'


export default ({ path, slot }) => {
  useEffect(() => {
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [path])

  return (
    <ins
      className="adsbygoogle"
      style={{ "display": "block" , textAlign: "center"  }}
     data-ad-client="ca-pub-5700105482538836"
     data-ad-slot={slot}
      data-ad-format="auto"
      data-full-width-responsive="true"
    />
  )
}