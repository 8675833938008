import React from 'react'

import { css } from '@emotion/react'

const Input = props => {
  const inputCss = () => css`
    input {
      display: block;

      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2pxs;
      border-bottom-left-radius: 2px;
      width: 100%;
      height: max(46px, 1rem);
      padding: 10px;
      padding-right: max(15px, 0.25rem);

      color: rgb(38, 38, 38);
      font-size: max(15px, 0.64rem);
      line-height: 1.0666666666666667rem;
      letter-spacing: -0.014506666666666668rem;
      border: none;
      border: 1px solid #000;

      border-image-source: initial;
      border-image-slice: initial;
      border-image-width: initial;
      border-image-outset: initial;
      border-image-repeat: initial;
      transition-duration: 0.25s, 0.25s;
      transition-timing-function: cubic-bezier(0.6, 0, 0.2, 1),
        cubic-bezier(0.6, 0, 0.2, 1);
      transition-delay: initial, initial;
      transition-property: background, border-color;
      outline-color: initial;
      outline-style: initial;
    }

    outline-width: 0px;
  `
  const {
    children,
    type,
    placeholder,
    name,
    classname,
    label,
    register,
    required,
    error,
    pattern,
    errorMessage,
    ...rest
  } = props

  const regObj = register
    ? {
        ...register(label, {
          required,
          pattern: pattern
        })
      }
    : ''
  return (
    <div css={inputCss} {...rest}>
      <input
        type={type}
        placeholder={placeholder}
        name={label}
        {...regObj}
        style={{ border: error ? '1px solid red' : '' }}
      />
      {error ? errorMessage : ''}
    </div>
  )
}

export default Input
