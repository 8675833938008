import React, { useEffect, useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import HeaderLogo from '../../components/modules/HeaderLogo'
import { css } from '@emotion/react'
import SnsList from '../../components/modules/SnsList'
import StoreContext from 'libs/context/StoreContext'
import * as Modules from '../modules'
import * as Action from 'libs/redux/action'
import * as Atoms from '../atoms'
import * as Common from '../../common'

import * as Libs from 'libs'
import { locaton } from '../../common/config'
const breakPoints = [413, 768, 960, 1280, 1280]

const headerCss = () =>
  css`
    position: fixed;
    z-index: 100;
    --grid-gap: 2rem;
    --grid-margin: 4rem;
    transition: all 0.75s cubic-bezier(0.71, 0.01, 0.45, 1.01);
    width: 100vw;
    transform: translateY(0px);

    box-sizing: border-box;

    background-color: #fff;

    .on-menu &,
    .on-menu-complete & {
      height: 100vh;
    }

    &:after {
      content: ' ';
      position: absolute;
      left: 0;
      border-bottom: 4px double #000;
      width: calc(100vw - 8em);
      max-width: calc(1600px - 8em);
      transform: translateX(-50%);
      left: 50%;
      ${Libs.Styles.Mq.moreTab} {
        bottom: 0px;
      }
      ${Libs.Styles.Mq.lessTab} {
        width: calc(100vw - 3em);
        top: 60px;
        z-index: 2;
      }
    }
    .init-loaded:not(.on-scrollinit):not(.on-scrolldown):not(.on-scrollup) & {
    }
    ${Libs.Styles.Mq.moreTab} {
      height: 145px;
    }
    ${Libs.Styles.Mq.lessPab} {
      height: 64px;
      &:before {
        transition: all 0.75s cubic-bezier(0.71, 0.01, 0.45, 1.01);
        content: ' ';
        position: absolute;
        background-color: #fff;
        width: 100vw;
        height: 100%;
        z-index: 1;
        .on-menu &,
        .on-menu-complete & {
          background-color: #ccc;
        }
      }
    }
    /*** Index ***/

    .category-index.on-scrolldown &,
    .category-contact.on-scrolldown & {
      ${Libs.Styles.Mq.moreTab} {
        transform: translateY(-95px) !important;
      }
    }
    .category-index.on-scrollup &,
    .category-contact.on-scrollup & {
      ${Libs.Styles.Mq.moreTab} {
        transform: translateY(-95px) !important;
      }
    }
    /*** Category ***/
    .type-category &,
    .category-contact & {
      ${Libs.Styles.Mq.moreTab} {
        transform: translateY(-95px);

        &:after {
          content: ' ';
          top: 140px;
          bottom: auto;
        }
      }
    }
    .type-category.on-scrolldown & {
      ${Libs.Styles.Mq.moreTab} {
        transform: translateY(-95px) !important;
      }
      ${Libs.Styles.Mq.lessPab} {
        transform: translateY(-104px) !important;
      }
    }
    .type-category.on-scrollup & {
      ${Libs.Styles.Mq.moreTab} {
        transform: translateY(-95px) !important;
      }
    }
    /*** Post ***/
    .type-page.category-map &,
    .type-post & {
      ${Libs.Styles.Mq.moreTab} {
        transform: translateY(-95px);
        &:after {
          content: ' ';
          top: 140px;
          bottom: auto;
        }
      }
      ${Libs.Styles.Mq.xlarge} {
      }
      ${Libs.Styles.Mq.tb} {
      }
    }
    .now-transition & {
      /*top: -200px;*/
    }
  `
const h1Css = () => css`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  ${Libs.Styles.Mq.moreLarge} {
    top: 40px;
  }
  ${Libs.Styles.Mq.pc} {
    top: 40px;
  }
  ${Libs.Styles.Mq.tb} {
    top: 46px;
  }
  ${Libs.Styles.Mq.lessPab} {
    top: 20px;
  }
`

const symbolCss = () => css`
  position: absolute;
  left: max(4em, calc((100vw - 1600px + 8em) / 2));
  top: 106px;
  transition: all 1s cubic-bezier(0.71, 0.01, 0.45, 1.01);
  opacity: 0;
  .type-category &,
  .type-page.category-map &,
  .type-post & {
    opacity: 1;
  }
  ${Libs.Styles.Mq.lessPab} {
    right: 10px;
    top: 17px;
    display: none;
  }
`

const bacCss = () => css`
  position: absolute;
  left: max(4em, calc((100vw - 1600px + 8em) / 2));
  top: 30px;
  transition: all 1s cubic-bezier(0.71, 0.01, 0.45, 1.01);

  ${Libs.Styles.Mq.tb} {
    left: 1.4em;
  }
  ${Libs.Styles.Mq.lessPab} {
    left: 1.4em;
    top: 20px;
    width: 20px;
    svg {
      height: 20px;
    }
    z-index: 2;
  }
  .category-index.on-scrolldown &,
  .category-index.on-scrollup &,
  .category-contact.on-scrolldown & {
    ${Libs.Styles.Mq.moreTab} {
      transform: translateY(80px) !important;
    }
  }
`
const snslistCss = () => css`
  li {
    line-height: 0;
  }
  ${Libs.Styles.Mq.moreTab} {
    position: absolute;
    right: max(7em, calc((100vw - 1600px + 14em) / 2));
    top: 30px;
    transition: all 1s cubic-bezier(0.71, 0.01, 0.45, 1.01);
  }

  ${Libs.Styles.Mq.tb} {
    right: 4.5em;
  }
  ${Libs.Styles.Mq.lessPab} {
    margin-left: 6vw;

    display: inline-block;
    vertical-align: middle;
    visibility: hidden;
    overflow: hidden;
    position: absolute;
    z-index: 3;
    .on-menu & {
      visibility: visible;
      top: 340px;
    }
  }
  .type-category &,
  .type-page.category-map &,
  .type-post & {
    ${Libs.Styles.Mq.moreTab} {
      transform: translateY(80px) !important;
    }
  }
  .category-index.on-scrolldown &,
  .category-index.on-scrollup &,
  .category-contact.on-scrolldown & {
    ${Libs.Styles.Mq.moreTab} {
      transform: translateY(80px) !important;
    }
  }
`

const langCss = () => css`
  ${Libs.Styles.Mq.moreTab} {
    position: absolute;
    right: max(4em, calc((100vw - 1600px + 8em) / 2));

    transition: all 1s cubic-bezier(0.71, 0.01, 0.45, 1.01);
    z-index: 1000;
  }
  ${Libs.Styles.Mq.moreLarge} {
    top: 30px;
    width: 45px;
  }
  ${Libs.Styles.Mq.pc} {
    top: 30px;
    width: 40px;
  }
  ${Libs.Styles.Mq.tb} {
    right: 1.5em;
    top: 30px;
    width: 40px;
  }
  ${Libs.Styles.Mq.lessPab} {
    visibility: hidden;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    margin-left: 14vw;
    width: 40px;
    .on-menu & {
      visibility: visible;
      position: absolute;
      z-index: 3;
      width: 12vw;
      top: 340px;
      span {
        max-width: 100px !important;
      }
    }
  }
  .type-category &,
  .type-page.category-map &,
  .type-post & {
    ${Libs.Styles.Mq.moreTab} {
      transform: translateY(80px) !important;
    }
  }

  .category-index.on-scrolldown &,
  .category-index.on-scrollup &,
  .category-contact.on-scrolldown & {
    ${Libs.Styles.Mq.moreTab} {
      transform: translateY(80px) !important;
    }
  }
`
const iconNav = () => {
  return css`
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    justify-self: right;
    overflow: hidden;
    position: absolute;
    cursor: pointer;
    right: 1.4em;
    top: 20px;
    pointer-events: auto;
    .menu-icon {
      overflow: hidden;
      div {
        transition: all 0.6s cubic-bezier(0.71, 0.01, 0.45, 1.01);
        transform-origin: left;
        transform: scaleX(1);
        .on-menu & {
          transform: scaleX(0);
        }
      }
    }
    .menu-text {
      display: inline-block;
      transition: all 0.6s cubic-bezier(0.71, 0.01, 0.45, 1.01);
      transform: translateX(100%);
    }
    .type-page.on-index-gnav-out &,
    .type-post.on-gnav-out &,
    .type-category.on-index-gnav-out & {
      pointer-events: auto;
      .menu-icon {
        div {
          transition-delay: 0.5s;
          transform: scaleX(1);
        }
      }
      .menu-text {
        transition-delay: 0.75s;
        transform: translateX(0%);
      }
    }
    ${Libs.Styles.Mq.moreTab} {
      display: none;
    }
    ${Libs.Styles.Mq.lessPab} {
      margin-top: -3px;
      z-index: 3;
    }
  `
}
const closeButtonCss = () => {
  return css`
    position: absolute;
    z-index: 10;
    pointer-events: none;
    cursor: pointer;
    top: 25px;
    right: 25px;
    ${Libs.Styles.Mq.lessPab} {
      top: 18px;
      right: 18px;
      .on-menu & {
        pointer-events: auto;
      }
    }
  `
}

const Header = props => {
  //--------------------------------------
  //  Scripts
  //--------------------------------------
  let catState = useSelector(state => state.TransitionReducer.category)
  const dispatch = useDispatch()

  const langInfo = Libs.Common.Func.getLangInfo()

  const langKey = Libs.Common.Func.getLangKey()
  const [lang, setLang] = useState(langKey)

  useEffect(() => {
    console.log('setType -- 1', Libs.Common.Config.currentPage)
  }, [catState])
  useEffect(() => {
    const langKey = Libs.Common.Func.getLangKey()
    setLang(langKey)
  }, [lang])

  const { restoreCheckout } = useContext(StoreContext)

  const clickHandler = (event, id) => {
    const fileName = Libs.Common.Func.getFileName(window.location.href)
    if (fileName === 'map') {
      window.location.href = `/${key}/map/`
    }
    const key = event.currentTarget.dataset.key

    let langInfo = Libs.Common.Func.getLangInfo()
    const langKey = Libs.Common.Func.getLangKey()

    if (langKey === key) return

    Libs.Common.Config.setCurrentLang(key)

    setLang(key)

    restoreCheckout()
    Common.Func.restoreData()
    dispatch(Action.LangChanged(key))
  }
  const hide = () => {
    let isMobile = false
    if (Libs.Common.Func.getDeviceSize() === 'lessPab') {
      isMobile = true
      dispatch(Action.MenuClick(null, false))
    }

    //
  }

  return (
    <>
      <header css={headerCss}>
        <div className={'inner-cont'}>
          <h1 css={h1Css}>
            <HeaderLogo onClickHandler={hide} />
          </h1>
          <div css={iconNav} className='hamburger-menu'>
            <Modules.HamburgerMenu />
          </div>
          <Libs.Atoms.ButtonClose
            css={closeButtonCss}
            onClick={hide}
            activeClass={'on-menu'}
            iconSize='30px'
            rectSize='3px'
          />
          <h2 css={symbolCss}>
            <Atoms.Symbol></Atoms.Symbol>
          </h2>
          <Modules.GlobalNavigation />
          {/*<div css={menuiconCss}>
          <HamburgerMenu />
        </div>*/}
          <Modules.Breadcrumb></Modules.Breadcrumb>
          {Libs.Common.Config.currentPage === 'index' ? (
            ''
          ) : (
            <Modules.TagFilter />
          )}
          <Libs.Atoms.IconBag css={bacCss} />
          <div css={snslistCss} className={'sns-list'}>
            <SnsList styles={{}} />
          </div>
          <Atoms.Dropdown
            className='lang-dropdown'
            css={langCss}
            active={lang}
            onMenuClick={hide}
            items={[
              {
                text: 'JA',
                slug: 'ja',
                url: langInfo[0].link,
                onClick: clickHandler
              },
              {
                text: 'EN',
                slug: 'en',
                url: langInfo[1] ? langInfo[1].link : '/',
                onClick: clickHandler
              }
            ]}
            trigerLink={
              Libs.Transition.TrigerPageTransition.TrigerPageTransition
            }
          />
        </div>
      </header>
    </>
  )
}
export default Header
