import { useStaticQuery, graphql } from 'gatsby'

export const useEnPostData = () => {
  const { allContentfulContentPost } = useStaticQuery(
    graphql`
      query journalListEnQuery {
        allContentfulContentPost(
          limit: 100
          sort: { order: DESC, fields: date }
          filter: { node_locale: { eq: "en" } }
        ) {
          edges {
            node {
              id
              slug
              title
              name
              description {
                raw
              }

              category {
                slug
                name
              }

              links {
                ... on ContentfulElementLink {
                  ...Link
                }
              }
              updatedAt(formatString: "MMMM DD, YYYY, h:mm:ss")

              node_locale
              thumbnail {
                gatsbyImageData(width: 600, quality: 70)
              }
              tags {
                name
                slug
                group
              }
              content {
                raw
                references {
                  ... on ContentfulElementGrid {
                    id
                    slug
                  }
                }
              }
              body {
                ... on ContentfulElementTextField {
                  id
                  slug
                }
              }
            }
          }
        }
      }
    `
  )
  return allContentfulContentPost.edges
}
