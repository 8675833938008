import React, { useEffect, useState, useRef } from 'react'

import { css } from '@emotion/react'
import * as Styles from '../styles'

import { TimelineMax } from 'gsap'
import { Power3 } from 'gsap'
import * as LibsAtoms from './'

export default props => {
  const { videoSrc, thumbnailForVideo, onload } = props
  const videoEl = useRef(null)
  const imgCont = useRef(null)
  const videoController = true

  let ratio
  const [imgContHeight, setImageHeight] = useState(0)

  useEffect(() => {
    console.log('videoEl --', videoEl)

    if (videoEl.current !== null) {
      const videoElement = videoEl.current

      videoElement.setAttribute('muted', 1)

      videoElement.addEventListener('emptied', event => {
        //console.log('imgLoadedList --- emptied', event)
      })
      videoElement.addEventListener('error', event => {
        // console.log('imgLoadedList --- onerror', event)
      })

      videoElement.addEventListener('loadedmetadata', event => {
        if (ratio === 1) {
          ratio = event.target.offsetWidth / event.target.offsetHeight
          setImageHeight(imgCont.current.clientWidth / ratio)
        }

        const tl = new TimelineMax()

        const ele = videoElement
        const cont = ele.closest('.video-inner-cont')
        tl.to({}, 0.5, {})
          .call(function () {
            cont.classList.add('loaded')
            cont.style.height = 'auto'
          })
          .to(
            ele,
            0.5,
            {
              opacity: 1,
              ease: Power3.easeInOut
            },
            'end'
          )
          .to(
            cont,
            0.5,
            {
              backgroundColor: 'rgba(255, 255, 255, 0)',
              ease: Power3.easeInOut
            },
            'end'
          )
      })
    }
  }, [])

  const videoContCss = () => {
    return css`
      position: relative;
      .button-volume {
      }
    `
  }
  const videoElementCss = () => {
    return css`
      width: 100%;
    `
  }
  const volumeButtonCss = () => {
    return css`
      position: absolute !important;
      bottom: 12px;
      right: 12px;
      z-index: 10;
    `
  }

  return (
    <div className={'img-inner-cont'} ref={imgCont}>
      <div css={videoContCss} className='video-inner-cont'>
        {/*<LibsAtoms.ButtonResume
                clickHandler={props => {
                  if (this.videoEl.paused) {
                    this.videoEl.play()
                  } else {
                    this.videoEl.pause()
                  }

                  return false
                }}
                id={styles.id}
              />*/}
        {videoController ? (
          <LibsAtoms.ButtonVolume
            clickHandler={props => {
              if (videoEl.muted) {
                videoEl.muted = false
              } else {
                videoEl.muted = true
              }

              return false
            }}
            css={volumeButtonCss}
          />
        ) : (
          ''
        )}
        <video
          css={videoElementCss}
          autoplay='autoplay'
          playsinline='1'
          loop='1'
          disablepictureinpicture='1'
          preload='1'
          data-plugin='videoAutoPlay'
          muted='0'
          poster={thumbnailForVideo}
          onLoadedData={event => {
            if (onload) onload(this)
          }}
          onError={event => {
            console.log('imgLoadedList --- error ', event)
          }}
          onMouseDown={props => {
            videoEl.current.pause()

            return false
          }}
          onTouchStart={props => {
            videoEl.current.pause()

            return false
          }}
          onMouseUp={props => {
            videoEl.current.play()

            return false
          }}
          onTouchEnd={props => {
            videoEl.current.play()

            return false
          }}
          ref={videoEl}
        >
          <source src={videoSrc} type='video/mp4' />
        </video>
      </div>
    </div>
  )
}
