import React, { useState, useRef } from 'react'
import { init, sendForm, send } from 'emailjs-com'
import { useForm } from 'react-hook-form'

import { useDispatch, useSelector } from 'react-redux'
import { OverlayOpen } from '../../libs/redux/action'
import * as Modules from '../modules'
import * as Atoms from '../atoms'

import * as Libs from 'libs'
import * as Common from '../../common'

import { css } from '@emotion/react'

const RegistrationForm = props => {
  const dispatch = useDispatch()
  const [fname, setName] = useState('')
  const [mail, setMail] = useState('')
  const form = useRef()

  const { register, handleSubmit, watch, formState } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      introduction: '',
      department: '',
      programingLanguage: ''
    }
  })
  const userID = process.env.REACT_APP_USER_ID
  const serviceID = process.env.REACT_APP_SERVICE_ID
  const templateID = process.env.REACT_APP_TEMPLATE_ID

  init(userID)

  const onSubmit2 = data => {
    sendForm(serviceID, templateID, form.current, userID).then(
      result => {
        console.log(result.text)
      },
      error => {
        console.log(error.text)
      }
    )
  }

  const formCss = () => css`
    background-color: ${Common.Config.keyColor2};
    ${Libs.Styles.Mq.moreTab} {
      padding-bottom: 100px;
    }

    ${Libs.Styles.Mq.lessPab} {
      padding-top: 70px;
      padding-bottom: 250px;
    }
    .logo-for-registration {
      text-align: center;
      margin-bottom: 12px;
      svg {
        width: auto;
        height: 38px;
      }
    }
    .gatsby-image-wrapper,
    form {
      margin: 0 auto;
      width: 850px;

      display: flex;
      row-gap: 30px;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      ${Libs.Styles.Mq.moreTab} {
        max-width: 80vw;
      }

      ${Libs.Styles.Mq.lessPab} {
        max-width: 88vw;
      }
      > * {
        width: 48%;
        &.full-size {
          width: 100%;
        }
        &.full-size-mb {
          ${Libs.Styles.Mq.lessPab} {
            width: 100%;
          }
        }
      }
    }
    .disable {
      display: none;
    }
    .submit-button {
      text-align: center;
      padding-bottom: 5px;
      margin: 0 auto;
      input {
        background-color: #000;
        border: 1px solid #000;
        color: #fff;
        cursor: pointer;
        padding: 18px 18px 18px 18px;
        width: 80%;
        font-size: 18px;
      }
    }
  `
  const h2Css = () => css`
    ${Libs.Styles.Mq.moreTab} {
      padding-bottom: 20px;
      padding-bottom: 80px;
      text-align: center;
    }
  `

  const onChangeName = e => {
    setName(e.target.value)
  }

  const { children, lang, placeholder, name, classname, ...rest } = props

  const Select01TextList = [
    { en: 'Reason For Contact', ja: 'お問い合わせ内容' },
    {
      en: 'Submit your press releases and information',
      ja: 'プレスリリースや情報の提供'
    },
    {
      en: 'Inquiries about collaboration products and e-commerce site',
      ja: 'コラボ商品、ECサイトに関するお問合わせ'
    },
    {
      en: 'General Inquiry',
      ja: 'その他のお問い合わせ'
    }
  ]
  return (
    <div css={formCss} className='form-cont' id={'form-cont'}>
      <Libs.Atoms.H2
        css={h2Css}
        styles={{
          fontPc: { fontSize: 20, fontFace: 'serif' }
        }}
      >
        Hello, Please fill out the form and send it to us.
      </Libs.Atoms.H2>
      <form ref={form} onSubmit={handleSubmit(onSubmit2)}>
        <Libs.Atoms.SelectDefault
          className={'full-size full-size-mb broker-select'}
          options={[
            { value: '', label: Select01TextList[0][lang] },
            { value: 'Yes', label: Select01TextList[1][lang] },
            { value: 'No', label: Select01TextList[2][lang] }
          ]}
          defaultValue={{ value: '', label: 'Are you a Realtor / Broker?' }}
        />
        <Libs.Atoms.Input
          type='text'
          id='name'
          placeholder='*First Name'
          label='firstName'
          register={register}
          required
          error={formState.errors.firstName}
          errorMessage={'First name is required'}
          onChange={onChangeName}
        />
        <Libs.Atoms.Input
          type='text'
          placeholder='*Last Name'
          label='lastName'
          register={register}
          required
          error={formState.errors.lastName}
          errorMessage={'Last name is required'}
          onChange={onChangeName}
        />
        <Libs.Atoms.Input
          type='email'
          placeholder='*Email'
          name='email'
          label='email'
          register={register}
          required
          pattern={`/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/`}
          error={formState.errors.email}
          errorMessage={'Please enter a Valid email'}
          onChange={onChangeName}
        />
        <Libs.Atoms.Input
          type='tel'
          placeholder='*Phone'
          name='phone'
          label='Phone'
          register={register}
          required
          pattern={`/^0[-0-9]{9,12}$/`}
          error={formState.errors.email}
          errorMessage={'Please enter Phone'}
          onChange={onChangeName}
        />
        <Libs.Atoms.Input
          className='full-size'
          type='text'
          register={register}
          placeholder='Address'
          name='address'
          label='address
'
        />
        <Libs.Atoms.Input
          type='text'
          register={register}
          placeholder='*City'
          name='city'
          label='city'
        />
        <Libs.Atoms.Input
          type='text'
          placeholder='Zip/Postal Code'
          name='zip'
          label='zip'
        />

        <Libs.Atoms.Textarea
          className='full-size '
          type='text'
          placeholder='Realtor’s Brokerage / Company'
          name='bfname'
        />

        <Libs.Atoms.CheckBox className='full-size' id='is-agent'>
          Yes send me updates*
        </Libs.Atoms.CheckBox>
        <div className='submit-button'>
          <input type='submit' value='Send' />
        </div>
      </form>
    </div>
  )
}

export default RegistrationForm
