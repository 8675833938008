import React, { Component } from 'react'
import { css } from '@emotion/react'
import * as Atoms from '../../components/atoms'
import * as Common from '../../common'
import * as Func from '../../common/func'
import * as Libs from 'libs'
import * as Hooks from '../../hooks'

export default props => {
  //--------------------------------------
  //  Libs.Styless
  //--------------------------------------
  const contCss = () => {
    return css`
      ${Libs.Common.Func.getPcSpVwValue('margin-top', 100, 60, true)};
      .category-map & {
        display: none;
      }
    `
  }
  const innerContCss = () => {
    //background-color: #f0F;
    return css`
      background-color: #fff;
      height: auto;
      width: calc(100vw - 8em);
      max-width: calc(1600px - 8em);
      margin: 0 auto;
      position: relative;
      z-index: 20;
      ${Libs.Styles.Mq.lessPab} {
        width: calc(100vw - 3em);
      }
      &:before {
        background-color: rgb(255, 255, 255);
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: rgb(226, 226, 226);
        border-top-width: 2px;
        border-top-style: solid;
        border-top-color: rgb(226, 226, 226);
        content: '';
        display: block;
        height: 1px;
        margin-top: 0px;
      }
      .filter-inner-cont {
        ${Libs.Styles.Mq.moreTab} {
          display: flex;
          margin-top: 50px;
          justify-content: space-between;
        }
        ${Libs.Styles.Mq.lessPab} {
          margin-top: 25px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          row-gap: 3rem;
          column-gap: 1rem;
        }
        ul {
          display: flex;
          flex-direction: column;
          gap: 8px;
          ${Libs.Styles.Mq.lessPab} {
            border-top: 1px solid #ccc;
          }

          padding-top: 20px;
          li {
            a {
              ${Libs.Styles.Font.Serif()};
            }

            span {
              ${Libs.Styles.Font.Bold()};
            }
          }
        }
      }
    `
  }

  const logoCss = () => {
    return css`
      ${Libs.Styles.Mq.moreTab} {
        margin-top: 50px !important;
        svg {
          width: 340px;
          height: auto;
          display: block;
          margin-left: -5px;
        }
      }
      ${Libs.Styles.Mq.lessPab} {
        margin-top: 30px !important;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        svg {
          width: 60vw;

          height: auto;
        }
      }
    `
  }

  const copyContCss = () => css`
    ${Libs.Common.Func.getPcSpVwValue('margin-top', 50, 50)};
    width: 100%;
    height: 60px;
    position: relative;
    bottom: 0px;
    background-color: #000;
  `
  const copyCss = () => css`
    display: flex;
    padding-top: 18px;
    margin-left: 5vw;
    font-size: 12px;
    color: #fff;
    > li {
      margin-right: 5vw;
    }
    ${Libs.Styles.Mq.lessPab} {
      width: 90vw;
      margin-left: 5%;
    }
  `

  //--------------------------------------
  //  Scripts
  //--------------------------------------

  const langKey = Libs.Common.Func.getLangKey()

  let data = Func.getData()

  data =
    langKey === 'ja'
      ? Hooks.AllMapData.AllMapData()
      : Hooks.AllMapDataEn.AllMapDataEn()
  Common.Func.setMapData(langKey, data)
  const categoryMap = Common.Func.categoryMap
  const tagsMap = Common.Func.tagsMap

  const obj = { 'food-and-drink': 0, 'art-culture': 1, fashion: 2, living: 3 }

  const keys = [...categoryMap.keys()]

  const NumArr = []
  for (var i = 0; i < keys.length; i++) {
    NumArr[obj[keys[i]]] = i
  }
  let values = [...categoryMap.values()]
  values = Common.Func.sortByOrder(values, NumArr)
  keys.sort(function (a, b) {
    return obj[a] - obj[b]
  })

  const sortValues = arr => {
    const priority = 'nocategory'

    return arr
      .filter(a => a.indexOf(priority) === -1)
      .concat(arr.filter(b => b.indexOf(priority) !== -1))
  }

  return (
    <div css={contCss}>
      <div css={innerContCss} className='footer'>
        <h2 css={logoCss}>
          <Atoms.Logo />
        </h2>

        <div className='filter-cont'>
          <div className='filter-inner-cont'>
            <ul>
              <li>
                <Libs.Atoms.TriggerLink to={`/${langKey}/map`}>
                  <Libs.Atoms.Span>Map</Libs.Atoms.Span>
                </Libs.Atoms.TriggerLink>
              </li>
              <li>
                <Libs.Atoms.TriggerLink to={`/${langKey}/contact`}>
                  <Libs.Atoms.Span>Contact</Libs.Atoms.Span>
                </Libs.Atoms.TriggerLink>
              </li>
              <li>
                <Libs.Atoms.TriggerLink to={`/${langKey}/shopping-info`}>
                  <Libs.Atoms.Span>Shopping Info</Libs.Atoms.Span>
                </Libs.Atoms.TriggerLink>
              </li>
              <li>
                <Libs.Atoms.TriggerLink to={`/${langKey}/privacy-policy`}>
                  <Libs.Atoms.Span>Privacy Policy</Libs.Atoms.Span>
                </Libs.Atoms.TriggerLink>
              </li>
            </ul>
            {keys.map((cat, i) => {
              return (
                <ul className='map-cat-cb-cont'>
                  <li>
                    <Libs.Atoms.TriggerLink
                      to={`/${langKey}/${tagsMap.get(cat).slug}`}
                    >
                      <Libs.Atoms.Span>{tagsMap.get(cat).name}</Libs.Atoms.Span>
                    </Libs.Atoms.TriggerLink>
                  </li>
                  {sortValues([...values[i].keys()]).map((value, i) => {
                    return tagsMap.get(value) ? (
                      <li>
                        <Libs.Atoms.TriggerLink
                          to={`/${langKey}/category/${tagsMap.get(value).slug}`}
                        >
                          {tagsMap.get(value).name}
                        </Libs.Atoms.TriggerLink>
                      </li>
                    ) : (
                      ''
                    )
                  })}
                </ul>
              )
            })}
          </div>
        </div>
      </div>
      <div css={copyContCss}>
        <ul css={copyCss}>
          <li>
            <Libs.Atoms.Span fontSize={10} letterSpacing={1}>
              ©2022 The New Bklyn Times
            </Libs.Atoms.Span>
          </li>
        </ul>
      </div>
    </div>
  )
}
