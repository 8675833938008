import React from 'react'
import { Global, css } from '@emotion/react'
import * as LibFont from 'libs/styles/fontface'
import * as Font from './fontface'
import * as Common from '../common'
import * as Libs from 'libs'

export const StoryStyle = props => {
  LibFont.setSanSerifFont(Common.Config.sansserifFont)
  LibFont.setSerifFont(Common.Config.serifFont)
  LibFont.setBoldFont(Common.Config.boldFont)
  LibFont.setItalicFont(Common.Config.italicFont)
  const gradientStanp = props => {
    return css`
      position: relative;
      display: block !important;
      svg {
        margin-top: 0px;
      }
      a {
        position: relative;
        white-space: nowrap !important;
        display: inline-flex !important;
        align-items: center;
        padding: 5px 13px 7px 8px;
        span {
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          position: relative;
          z-index: 100;
          line-height: normal !important;
        }
        &:before {
          content: ' ';
          position: absolute;
          background-color: #fff;
          border-radius: 10px;
          width: 100%;
          height: 100%;
          left: 0px;
          z-index: 0;
        }
      }
    `
  }

  return (
    <Global
      styles={css`
        html[lang='en'] {
          .rt-cont {
            h1,
            h2,
            h3,
            h4,
            .default-text {
              ${Libs.Styles.Font.Serif()};
            }
            h1 {
              ${Libs.Common.Func.getPcSpVwValue(
                'font-size',
                60,
                32,
                true,
                10,
                1
              )};
              ${Libs.Common.Func.getPcSpVwValue(
                'line-height',
                60,
                34,
                true,
                10,
                1
              )};
            }
            h2 {
              ${Libs.Common.Func.setPcVwValues('font-size', 36, true)};
              ${Libs.Common.Func.setPcVwValues('line-height', 42, true)};
              ${Libs.Common.Func.setSpVwValues('font-size', 30, true)};
              ${Libs.Common.Func.setSpVwValues('line-height', 39, true)};
            }
            h3 {
              ${Libs.Common.Func.setPcVwValues('font-size', 28, true)};
              ${Libs.Common.Func.setPcVwValues('line-height', 38, true)};
              ${Libs.Common.Func.setSpVwValues('font-size', 21, true)};
              ${Libs.Common.Func.setSpVwValues('line-height', 24, true)};
            }
            h4 {
              ${Libs.Common.Func.getPcSpVwValue('font-size', 28, 18, true)};
              ${Libs.Common.Func.getPcSpVwValue('line-height', 28, 20, true)};
            }
            .small-text {
              ${Libs.Common.Func.setPcVwValues('font-size', 16, true)};
              ${Libs.Common.Func.setPcVwValues('line-height', 22, true)};
              ${Libs.Common.Func.setSpVwValues('font-size', 14, true)};
              ${Libs.Common.Func.setSpVwValues('line-height', 20, true)};
            }
            .medium-text {
              ${Libs.Common.Func.setPcVwValues('font-size', 18, true)};
              ${Libs.Common.Func.setPcVwValues('line-height', 26, true)};
              ${Libs.Common.Func.setSpVwValues('font-size', 16, true)};
              ${Libs.Common.Func.setSpVwValues('line-height', 22, true)};
            }
            .default-text {
              ${Libs.Common.Func.getPcSpVwValue(
                'font-size',
                18,
                16,
                true,
                1,
                1
              )};
              ${Libs.Common.Func.getPcSpVwValue(
                'line-height',
                28,
                24,
                true,
                1,
                1
              )};
            }
          }
        }
        .content-caption {
          h1 {
            ${Libs.Common.Func.setPcVwValues('font-size', 48, true)};
            ${Libs.Common.Func.setPcVwValues('line-height', 50, true)};
            ${Libs.Common.Func.setSpVwValues('font-size', 36, true)};
            ${Libs.Common.Func.setSpVwValues('line-height', 42, true)};
          }
          h2 {
            ${Libs.Common.Func.setPcVwValues('font-size', 36, true)};
            ${Libs.Common.Func.setPcVwValues('line-height', 42, true)};
            ${Libs.Common.Func.setSpVwValues('font-size', 32, true)};
            ${Libs.Common.Func.setSpVwValues('line-height', 40, true)};
          }
          h3 {
            ${Libs.Common.Func.setPcVwValues('font-size', 28, true)};
            ${Libs.Common.Func.setPcVwValues('line-height', 38, true)};
            ${Libs.Common.Func.setSpVwValues('font-size', 24, true)};
            ${Libs.Common.Func.setSpVwValues('line-height', 28, true)};
          }
          .small-text {
            ${Libs.Common.Func.setPcVwValues('font-size', 16, true)};
            ${Libs.Common.Func.setPcVwValues('line-height', 22, true)};
            ${Libs.Common.Func.setSpVwValues('font-size', 14, true)};
            ${Libs.Common.Func.setSpVwValues('line-height', 20, true)};
          }
          .medium-text {
            ${Libs.Common.Func.setPcVwValues('font-size', 18, true)};
            ${Libs.Common.Func.setPcVwValues('line-height', 26, true)};
            ${Libs.Common.Func.setSpVwValues('font-size', 16, true)};
            ${Libs.Common.Func.setSpVwValues('line-height', 22, true)};
          }
          .default-text {
            ${Libs.Common.Func.getPcSpVwValue('font-size', 16, 16, true, 1, 1)};
            ${Libs.Common.Func.getPcSpVwValue(
              'line-height',
              22,
              22,
              true,
              2,
              1
            )};
          }
        }

        html[lang='ja'] {
          .rt-cont {
            h1,
            h2,
            h3,
            h4,
            .default-text {
              ${Libs.Styles.Font.Serif()};
            }
            h1 {
              ${Libs.Common.Func.getPcSpVwValue(
                'font-size',
                52,
                28,
                true,
                10,
                1
              )};
              ${Libs.Common.Func.getPcSpVwValue(
                'line-height',
                60,
                34,
                true,
                10,
                1
              )};
            }
            h2 {
              ${Libs.Common.Func.setPcVwValues('font-size', 36, true)};
              ${Libs.Common.Func.setPcVwValues('line-height', 42, true)};
              ${Libs.Common.Func.setSpVwValues('font-size', 25, true)};
              ${Libs.Common.Func.setSpVwValues('line-height', 31, true)};
            }
            h3 {
              ${Libs.Common.Func.setPcVwValues('font-size', 28, true)};
              ${Libs.Common.Func.setPcVwValues('line-height', 38, true)};
              ${Libs.Common.Func.setSpVwValues('font-size', 22, true)};
              ${Libs.Common.Func.setSpVwValues('line-height', 28, true)};
            }
            h4 {
              ${Libs.Common.Func.getPcSpVwValue('font-size', 28, 22, true)};
              ${Libs.Common.Func.getPcSpVwValue('line-height', 28, 22, true)};
              ${Libs.Common.Func.setSpVwValues('font-size', 19, true)};
              ${Libs.Common.Func.setSpVwValues('line-height', 26, true)};
            }
            .small-text {
              ${Libs.Common.Func.setPcVwValues('font-size', 14, true)};
              ${Libs.Common.Func.setPcVwValues('line-height', 20, true)};
              ${Libs.Common.Func.setSpVwValues('font-size', 14, true)};
              ${Libs.Common.Func.setSpVwValues('line-height', 20, true)};
            }
            .medium-text {
              ${Libs.Common.Func.setPcVwValues('font-size', 18, true)};
              ${Libs.Common.Func.setPcVwValues('line-height', 26, true)};
              ${Libs.Common.Func.setSpVwValues('font-size', 16, true)};
              ${Libs.Common.Func.setSpVwValues('line-height', 22, true)};
            }
            .default-text {
              ${Libs.Common.Func.getPcSpVwValue(
                'font-size',
                16,
                15,
                true,
                1,
                1
              )};
              ${Libs.Common.Func.getPcSpVwValue(
                'line-height',
                26,
                24,
                true,
                1,
                1
              )};
            }
          }

          .content-caption {
            h1 {
              ${Libs.Common.Func.setPcVwValues('font-size', 38, true)};
              ${Libs.Common.Func.setPcVwValues('line-height', 42, true)};
              ${Libs.Common.Func.setSpVwValues('font-size', 28, true)};
              ${Libs.Common.Func.setSpVwValues('line-height', 38, true)};
            }
            h2 {
              ${Libs.Common.Func.setPcVwValues('font-size', 32, true)};
              ${Libs.Common.Func.setPcVwValues('line-height', 38, true)};
              ${Libs.Common.Func.setSpVwValues('font-size', 24, true)};
              ${Libs.Common.Func.setSpVwValues('line-height', 36, true)};
            }
            h3 {
              ${Libs.Common.Func.setPcVwValues('font-size', 24, true)};
              ${Libs.Common.Func.setPcVwValues('line-height', 36, true)};
              ${Libs.Common.Func.setSpVwValues('font-size', 20, true)};
              ${Libs.Common.Func.setSpVwValues('line-height', 32, true)};
            }
            .small-text {
              ${Libs.Common.Func.setPcVwValues('font-size', 14, true)};
              ${Libs.Common.Func.setPcVwValues('line-height', 20, true)};
              ${Libs.Common.Func.setSpVwValues('font-size', 14, true)};
              ${Libs.Common.Func.setSpVwValues('line-height', 20, true)};
            }
            .default-text {
              ${Libs.Common.Func.getPcSpVwValue(
                'font-size',
                13,
                13,
                true,
                2,
                1
              )};
              ${Libs.Common.Func.getPcSpVwValue(
                'line-height',
                22,
                22,
                true,
                2,
                1
              )};
            }
            .medium-text {
              ${Libs.Common.Func.setPcVwValues('font-size', 17, true)};
              ${Libs.Common.Func.setPcVwValues('line-height', 26, true)};
              ${Libs.Common.Func.setSpVwValues('font-size', 15, true)};
              ${Libs.Common.Func.setSpVwValues('line-height', 24, true)};
            }
          }
          .content-title-for-info {
            ${Libs.Common.Func.setPcVwValues('font-size', 60, true)};
          }
        }
        .rt-cont {
          table {
            width: 100%;

            text-transform: uppercase;
            ${Libs.Styles.Mq.lessPab} {
              display: block;
              max-width: 88vw;
              overflow-x: scroll;
            }
            th {
              background-color: #ebe9e7;
            }
            th,
            td:first-child {
              span {
                ${Libs.Styles.Font.Bold(true)};
              }
            }
            td,
            th {
              min-width: 30px;
              text-align: right;
              border: 0px solid;
              border-bottom-width: 1px;
              font-size: 0.875rem;
              border-color: #ebe9e7;
              padding: 0.5rem;
              &:first-child {
                text-align: left;
              }
            }
          }
        }
        ${Font.FontFace()}
        .round-background-white {
          background-color: #fff;
          color: #000;
          border-radius: 10px;
          padding: 10px;
          white-space: nowrap;
          display: inline-block !important;
        }
        .link-icon-element {
          svg {
            vertical-align: middle;
            margin-right: 5px;
            margin-top: -3px;
          }
        }
        .round-background-gray {
          background-color: #cccccc;
          color: #fff;
          border-radius: 10px;
          padding: 10px;
          white-space: nowrap;
          display: inline-block !important;
        }
        .red-yelow-whitebg-stamp {
          ${gradientStanp()}
          svg {
            fill: #e21000;
          }
          span {
            background-image: linear-gradient(to left, #f5c31c, orange, red);
          }
        }
        .violet-blue-whitebg-stamp {
          ${gradientStanp()}
          svg {
            fill: violet;
          }
          span {
            background-image: linear-gradient(to left, blue, indigo, violet);
          }
        }
        .rainbow-whitebg-stamp {
          ${gradientStanp()}
          span {
            background-image: linear-gradient(
              to left,
              violet,
              indigo,
              blue,
              green,
              yellow,
              orange,
              red
            );
          }
        }
        .block-bg-white {
          background-color: #fff;
          padding-top: 8px;
          padding-bottom: 10px;
          padding-left: 8px !important;
          padding-right: 8px !important;
        }
        .inline-bg-white {
          padding-left: 10px !important;
          padding-right: 10px !important;

          p {
            display: inline;
            background-color: #fff;
            box-shadow: 10px 0 0px 0px #fff, -10px 0 0px 0px #fff;
            padding-top: 1px;
            padding-bottom: 1px;
            color: black;
          }
        }
        .inline-bg-black {
          padding-left: 10px !important;
          padding-right: 10px !important;
          p {
            display: inline;
            background-color: #000;
            box-shadow: 10px 0 0px 0px #000, -10px 0 0px 0px #000;
            padding-top: 1px;
            padding-bottom: 1px;
            color: white;
          }
        }
        :root {
          --primary: #22d2a0;
          --secondary: #fff;
          --background: #fff;
          --green: #1fc11b;
          --yellow: #000;
          --orange: #ff9c55;
          --red: #ff5555;
        }
        .cards {
          background-color: var(--background);
          display: block;
          width: 300px;
          min-height: 90px;
          cursor: pointer;
          padding: 15px;
          margin: calc(50vh - 30px) auto 0 auto;
          border: 3px solid var(--primary);
          box-shadow: 10px -10px 0 -3px var(--yellow), 10px -10px var(--yellow),
            20px -20px 0 -3px var(--yellow), 20px -20px var(--yellow);
        }
        .video-cont {
          video {
            object-fit: cover;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
          }
        }
        .css-1lnwsbr iframe {
          height: auto !important;
        }
      `}
    />
  )
}
