import React from "react";

import { css } from "@emotion/react";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const Toggles = (props) => {
	const { onChange, options, defaultValue, ...rest } = props;
	const [variant, setVariant] = React.useState(defaultValue.value);

	const toggleGroupCss = () => css`
		button {
			color: #000;
			margin-right: 10px;
			border-radius: 0;
			border-left: 0;
			border-right: 0;
			border-bottom: 0;
			width: 22%;
			&.Mui-selected {
				background-color: #000;
				color: #fff;
			}
		}
	`;

	const handleVariant = (event, newVariant) => {
		if (newVariant !== null) {
			setVariant(newVariant);
			onChange({ label: newVariant, value: newVariant });
		}
	};
	return (
		<div>
			<ToggleButtonGroup
				value={variant}
				css={toggleGroupCss}
				exclusive
				onChange={handleVariant}
				aria-label="text variant"
				{...rest}
			>
				{options.map((item, i) => {
					if (item.availableForSale) {
						return (
							<ToggleButton value={item.value} aria-label={item.value}>
								{item.value}
							</ToggleButton>
						);
					} else {
						return (
							<ToggleButton value={item.value} aria-label={item.value} disabled>
								{item.value}
							</ToggleButton>
						);
					}
				})}
			</ToggleButtonGroup>
		</div>
	);
};

export default Toggles;
