import React from 'react'
import { css } from '@emotion/react'
import * as Libs from 'libs'
import { GatsbyImage } from 'gatsby-plugin-image'

import * as Atoms from './'

import { WindowResize } from 'libs/redux/event/WindowResize'
export default props => {
  const { item, type } = props
  const imgcardCont = () => {
    return css`
      column-gap: var(--grid-gap);
      row-gap: var(--grid-gap);
      row-gap: var(--grid-gap);

      border-top: 1px solid #000;
      padding-top: 2em;

      &:first-child {
        ${type !== 'index' ? `border: none;` : ''}
      }
      ${Libs.Styles.Mq.moreTab} {
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        grid-column: 1 / span 9;
        --grid-gap: 2rem;
        --grid-margin: 4rem;
      }
      ${Libs.Styles.Mq.lessPab} {
        display: grid;
        --grid-gap: 1rem;
        --grid-margin: 1.5rem;
      }
    `
  }
  const gridText = props => {
    return css`
      ${Libs.Styles.Mq.moreTab} {
        grid-column: 1 / span 3;
        grid-row: 1 / span 1;
      }
      ${Libs.Styles.Mq.lessPab} {
        grid-column: 1 / -1;
        grid-row: 2 / span 1;
      }
    `
  }
  const imgLinkCss = props => {
    const { slug } = props
    const spHeight = slug === 'journal' ? 48 : 80
    const pcHeight = 30

    return css`
      ${Libs.Styles.Mq.tb} {
        grid-column: 4 / span 6;
        height: 42vw;
      }
      ${Libs.Styles.Mq.pc} {
        grid-column: 4 / span 6;
        height: ${pcHeight}vw;
      }
      ${Libs.Styles.Mq.large} {
        grid-column: 4 / span 6;
        height: 28vw;
      }
      ${Libs.Styles.Mq.xlarge} {
        grid-column: 4 / span 6;
        height: 28vw;
      }
      ${Libs.Styles.Mq.lessPab} {
        grid-column: 1 / -1;
        height: 48vw;
      }
      ${Libs.Styles.Mq.sp} {
        height: ${spHeight}vw;
      }
    `
  }
  const imgCss = props => {
    return css`
      width: 100%;
      height: 100%;
    `
  }

  const imgSrc =
    WindowResize.currentType(WindowResize.lessPab) && item.thumbnailSp
      ? item.thumbnailSp
      : item.thumbnail

  return (
    <article css={imgcardCont}>
      <Libs.Atoms.TriggerLink
        css={imgLinkCss}
        to={`/${item.node_locale}/${item.category.slug}/${item.slug}/`}
      >
        {imgSrc ? (
          <div css={imgCss} className={'img-cont img-element'}>
            <GatsbyImage
              css={imgCss}
              loading='eager'
              image={imgSrc.gatsbyImageData}
            />
          </div>
        ) : (
          ''
        )}
      </Libs.Atoms.TriggerLink>
      <div css={gridText} lassName={'text-element'}>
        <Atoms.ImageCardDescription
          item={item}
          lineCount={10}
        ></Atoms.ImageCardDescription>
      </div>
    </article>
  )
}
