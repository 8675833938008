import React from 'react'

import { css } from '@emotion/react'

import ReactSelect from 'react-select'

const SelectDefault = props => {
  const optionSelectCss = () => css`
    position: relative;
    width: 100%;
    &:before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.8em;
      width: 0;
      height: 0;
      padding: 0;
      content: '';
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #000;
      pointer-events: none;
    }

    select {
      width: 100%;
      height: 46px;
      padding: 2px 8px;
      font-size: inherit;
      font-family: inherit;
      cursor: pointer;
      text-indent: 0.01px;
      text-overflow: ellipsis;

      outline: none;
      background: transparent;
      background-image: none;
      box-shadow: none;
      appearance: none;
      background-color: #fff;
      border: 1px solid #000;
      border-radius: 0px;
    }
  `
  const { onChange, options, defaultValue, ...rest } = props

  return (
    <div css={optionSelectCss} defaultValue={defaultValue} {...rest}>
      <select onChange={onChange ? onChange : null}>
        {options &&
          options.map((item, i) => {
            return <option value={item.value}>{item.label}</option>
          })}
      </select>
    </div>
  )
}

export default SelectDefault
